import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { UserEditService, UserSearchService } from '../services';
import { SharedModule } from '../shared';
import { EditFormComponent } from './edit-form';
import { NoteEditComponent } from './note-edit';
import { PasswordEditComponent } from './password-edit';
import { SortableHeaderComponent } from './sortable-header';
import { UserEditComponent } from './user-edit';
import { UserListComponent } from './user-list';
import { UserSearchComponent } from './user-search';
import { UserTableComponent } from './user-table';

@NgModule({
	declarations: [
		UserListComponent,
		UserEditComponent,
		UserTableComponent,
		UserSearchComponent,
		SortableHeaderComponent,
		EditFormComponent,
		PasswordEditComponent,
		NoteEditComponent
	],
	exports: [
		UserListComponent,
		UserEditComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule,
		NgbAccordionModule,
		SharedModule
	],
	providers: [
		UserEditService,
		UserSearchService
	]
})
export class UsersModule { }
