export interface AdminUserSearchOption {
	/**
	* Filter options.
	*/
	filter?: FilterOption;
	/**
	* Sort criteria.
	*/
	sortOrder?: SortOption;
}

export interface FilterOption {
	/**
	* User email.
	*/
	email?: string;
	/**
	* User first name.
	*/
	firstName?: string;
	/**
	* User last name.
	*/
	lastName?: string;
	/**
	* User country.
	*/
	country?: string;
	/**
	* User role.
	*/
	role?: string;
	/**
	* User role info.
	*/
	roleInfo?: string;
	/**
	* User unprocessed status.
	*/
	unprocessed?: boolean;
	/**
	* User approved status.
	*/
	approved?: boolean;
	/**
	* User denied status.
	*/
	denied?: boolean;
	/**
	* User active status.
	*/
	active?: boolean;
	/**
	* User suspended status.
	*/
	suspended?: boolean;
}

export interface SortOption {
	/**
	* Field name to order.
	*/
	name?: SortFields;
	/**
	* Order mode.
	*/
	desc?: boolean;
}

/**
* Fields for sort order
*/
export enum SortFields {
	requestDate = 'RequestDate'
}
