<h1 class="mb-5">{{ labels.APPROVER_EDIT_COMPONENT_TITLE }}</h1>

<form [formGroup]="approverForm">

  <div class="row">

    <div class="col-3">
      <div class="form-group mb-0">
        <label for="country" class="mb-0">{{ labels.APPROVER_EDIT_COMPONENT_COUNTRY }}</label>
        <select class="form-control custom-select" id="country" autocomplete="nope" formControlName="country"
          [compareWith]="compareCountry" (change)="updateApproverList()"
          [ngClass]="{'is-valid': approverForm.controls.country.valid && approverForm.controls.country.touched, 'is-invalid': approverForm.controls.country.invalid}">
          <option [ngValue]="null">-</option>
          <option *ngFor="let country of countryOptions" [ngValue]="country">{{ country?.name }}</option>
        </select>
        <div class="invalid-feedback" *ngIf="approverForm.controls.country.invalid">
          {{approverForm.controls.country["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3"></div>

    <div class="col-4">
      <div class="form-group mb-0">
        <label for="email" class="mb-0">{{ labels.APPROVER_EDIT_COMPONENT_EMAIL }}</label>
        <input type="text" autocomplete="nope" class="form-control" id="email" formControlName="email"
          placeholder="{{ labels.APPROVER_EDIT_COMPONENT_EMAIL }}"
          [ngClass]="{'is-valid': approverForm.controls.email.valid && approverForm.controls.email.touched, 'is-invalid': approverForm.controls.email.invalid && approverForm.controls.email.touched}" />
        <div class="invalid-feedback" *ngIf="approverForm.controls.email.invalid">
          {{approverForm.controls.email["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-2">
      <button type="button" class="btn btn-primary" style="margin-top: 2rem;" [disabled]="approverForm.invalid"
        (click)="submitForm()">
        {{ labels.APPROVER_EDIT_COMPONENT_BTN_ADD }}
      </button>
    </div>

  </div>

</form>

<div class="mt-3 mb-3">
  <div class="row">
    <div class="col-lg-6 d-flex align-items-end">
      <span class="results">{{ approverList?.length ?? 0 }} {{ labels.APPROVER_EDIT_COMPONENT_RESULTS }}</span>
    </div>
  </div>
</div>

<ng-container *ngIf="approverForm.value.country">
  <div class="panel panel-thead w-50">
    <div class="row no-gutters">

      <div class="col w-75 no-link">
        {{ labels.APPROVER_EDIT_COMPONENT_THEAD }} &nbsp; {{ approverForm.value.country?.name ?? '-' }}
      </div>
      <div class="col no-link"></div>

    </div>
  </div>

  <ng-template #loadedList>

    <div class="w-50" style="max-height: 39rem; overflow-x: auto;">
      <div class="panel panel-tr" *ngFor="let approver of approverList">
        <div class="row no-gutters">

          <div class="col w-75">
            {{ approver?.email }}
          </div>
          <div class="col">
            <i class="material-icons material-icons-outlined action" (click)="openDeleteModal(approver?.id)"
              title="{{ labels.APPROVER_EDIT_COMPONENT_DELETE }}">delete</i>
          </div>

        </div>
      </div>
    </div>

  </ng-template>

  <div class="w-50" *ngIf="isLoading; else loadedList"
    style="max-height: 39rem; overflow-x: auto; opacity: 0.4; line-height: 0;">
    <div class="panel panel-tr" *ngFor="let ghost of ghostList">
      <div class="row no-gutters">

        <div class="col w-75">
          <div class="ghost-line"></div>
        </div>
        <div class="col">
          <i class="material-icons action">delete</i>
        </div>

      </div>
    </div>
  </div>

</ng-container>
