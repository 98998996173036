import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { delay, finalize } from 'rxjs/operators';
import { Approver, Country, LanguageService, ToastService, compareCountry } from '../core';
import { ApproverForm } from '../models';
import { ApproverService } from '../services';
import { BaseComponent, ConfirmationComponent } from '../shared';



/**
 * Component of the page to handle approvers. 
 */
@Component({
	selector: 'approver-edit',
	templateUrl: './approver-edit.component.html'
})
export class ApproverEditComponent extends BaseComponent implements OnInit {

	approverForm: RxFormGroup;
	countryOptions: Country[];
	approverList: Approver[];
	isSaving: boolean;
	isLoading: boolean;

	ghostList = new Array(3).fill(1);

	compareCountry = compareCountry;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private approverSrv: ApproverService,
		private toastSrv: ToastService,
		private modalSrv: NgbModal
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.initForm();
		this.approverSrv.getCountryList().subscribe(list => this.countryOptions = list);
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.approverForm = <RxFormGroup>this.formBuilder.formGroup(ApproverForm);
	}

	/**
	 * Update list of approvers.
	 */
	updateApproverList(): void {
		const countryCode = this.approverForm.value.country?.code;
		this.approverList = [];
		if (countryCode) {
			this.isLoading = true;
			this.approverSrv.getApproverList(countryCode).pipe(
				delay(500),
				finalize(() => this.isLoading = false)
			).subscribe(list => this.approverList = [...list]);
		}
	}

	/**
	 * Submit form to create new approver.
	 */
	submitForm(): void {
		if (this.approverForm.valid) {
			this.isSaving = true;
			this.approverSrv.createApprover(<ApproverForm>this.approverForm.modelInstance).pipe(
				finalize(() => this.isSaving = false)
			).subscribe(
				() => {
					this.toastSrv.showSuccess(this.labels['APPROVER_EDIT_COMPONENT_ADD_SUCCESS']);
					this.updateApproverList();
				}, () => this.toastSrv.showError(this.labels['APPROVER_EDIT_COMPONENT_ADD_ERROR']),
			)
		}
	}

	/**
	 * Open modal to confirm delete action.
	 * @param approverId Guid id of approver to delete
	 */
	openDeleteModal(approverId: string): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.langSrv.getLabels()['APPROVER_EDIT_COMPONENT_DELETE_MODAL_TITLE'],
			message: this.langSrv.getLabels()['APPROVER_EDIT_COMPONENT_DELETE_MODAL_MSG'],
			confirmBtn: this.langSrv.getLabels()['APPROVER_EDIT_COMPONENT_DELETE_MODAL_CONFIRM_BTN'],
			cancelBtn: this.langSrv.getLabels()['APPROVER_EDIT_COMPONENT_DELETE_MODAL_CANCEL_BTN']
		};
		modalRef.result.then(() => this.delete(approverId)).catch(error => false);
	}

	/**
	 * Delete approver.
	 * @param approverId Guid id of approver to delete
	 */
	private delete(approverId: string): void {
		this.approverSrv.deleteApprover(approverId).subscribe(
			() => {
				this.toastSrv.showSuccess(this.labels['APPROVER_EDIT_COMPONENT_DELETE_SUCCESS']);
				this.updateApproverList();
			}, () => this.toastSrv.showError(this.labels['APPROVER_EDIT_COMPONENT_DELETE_ERROR']))
	}

}
