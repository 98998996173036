import { email, maxLength, pattern, required, trim } from "@rxweb/reactive-form-validators";
import { Country } from "../core";

const specialChars: RegExp = /^[^<>%#&?,]+$/;


/**
 * Approver creation form model.
 */
export class ApproverForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_EMAIL" })
	@maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_EMAIL" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_EMAIL" })
	@email({ messageKey: "VALIDATION_MESSAGE_FORMAT_EMAIL" })
	@trim()
	email: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COUNTRY" })
	country: Country;
}