import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserRepository } from './Iuser.repository';
import { AdminNoteInsert, AdminUserItem, AdminUserSearchOption, ApiResponse, Approver, ApproverInsert, CountryProduct, DemoUser, DemoUsersRequest, DemoUsersRequestInsert, Dictionary, Note, Product, USER_DICTIONARY_LIST, User, UserPassword, UserStatus } from './models';

@Injectable()
export class UserRepository implements IUserRepository {

	constructor(private httpClient: HttpClient) { }

	getDictionaries(namelist: string = USER_DICTIONARY_LIST): Observable<ApiResponse<Dictionary[]>> {
		const params = new HttpParams().set('nameList', namelist);
		return this.httpClient.get<ApiResponse<Dictionary[]>>(environment.domainUserAPI + '/api/request/getdictionaries', { params: params });
	}

	getUserProfile(): Observable<ApiResponse<User>> {
		return this.httpClient.get<ApiResponse<User>>(environment.domainUserAPI + "/api/users/getuserprofile");
	}

	getUser(id: string): Observable<ApiResponse<User>> {
		const options = {
			params: { id }
		};
		return this.httpClient.get<ApiResponse<User>>(environment.domainUserAPI + '/api/admin/getuserprofile', options);
	}

	getUserList(option: AdminUserSearchOption): Observable<ApiResponse<AdminUserItem[]>> {
		return this.httpClient.post<ApiResponse<AdminUserItem[]>>(environment.domainUserAPI + '/api/admin/getuserlist', option);
	}

	changeUserStatus(userStatus: UserStatus): Observable<ApiResponse<boolean>> {
		return this.httpClient.patch<ApiResponse<boolean>>(environment.domainUserAPI + '/api/admin/changeuserstatus', userStatus);
	}

	editUser(user: User): Observable<ApiResponse<void>> {
		return this.httpClient.put<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/edituser', user);
	}

	changeUserPassword(userPassword: UserPassword): Observable<ApiResponse<void>> {
		return this.httpClient.patch<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/changeuserpassword', userPassword);
	}

	insertNote(note: AdminNoteInsert): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/insertnote', note);
	}

	getNoteList(userGuid: string): Observable<ApiResponse<Note[]>> {
		const params = new HttpParams().set('userGuid', userGuid);
		return this.httpClient.get<ApiResponse<Note[]>>(environment.domainUserAPI + '/api/admin/getnotelist', { params: params });
	}

	createApprover(approver: ApproverInsert): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/createapprover', approver);
	}

	getApproverList(countryCode: string): Observable<ApiResponse<Approver[]>> {
		const params = new HttpParams().set('countryCode', countryCode);
		return this.httpClient.get<ApiResponse<Approver[]>>(environment.domainUserAPI + '/api/admin/getapproverlist', { params: params });
	}

	deleteApprover(approverGuid: string): Observable<ApiResponse<void>> {
		const params = new HttpParams().set('approverGuid', approverGuid);
		return this.httpClient.delete<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/deleteapprover', { params: params });
	}

	createDemoEvent(request: DemoUsersRequestInsert): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainUserAPI + '/api/admin/createdemousersrequest', request);
	}

	getDemoUserList(): Observable<ApiResponse<DemoUser[]>> {
		return this.httpClient.get<ApiResponse<DemoUser[]>>(environment.domainUserAPI + '/api/admin/getdemousers');
	}

	getDemoUserRequestList(): Observable<ApiResponse<DemoUsersRequest[]>> {
		return this.httpClient.get<ApiResponse<DemoUsersRequest[]>>(environment.domainUserAPI + '/api/admin/getdemousersrequests');
	}

	getDemoUserCredentialsPdf(requestGuid: string): Observable<Blob> {
		const params = new HttpParams().set('requestGuid', requestGuid);
		return this.httpClient.get(environment.domainUserAPI + '/api/admin/getdemousercredentialdocument', { params: params, responseType: 'blob' });
	}

	getProductList(): Observable<ApiResponse<Product[]>> {
		return this.httpClient.get<ApiResponse<Product[]>>(environment.domainUserAPI + '/api/product/list');
	}

	getCountryProducts(countryCode?: string): Observable<ApiResponse<CountryProduct[]>> {
		let params = new HttpParams();
		if (countryCode) params = params.set('countryCode', countryCode);
		return this.httpClient.get<ApiResponse<CountryProduct[]>>(environment.domainUserAPI + '/api/Product/CountryProducts', { params: params });
	}

	editCountryProducts(countryCode: string, productCodes: string[]): Observable<ApiResponse<void>> {
		const params = new HttpParams().set('countryCode', countryCode);
		return this.httpClient.post<ApiResponse<void>>(environment.domainUserAPI + '/api/Admin/CountryProducts', productCodes, { params: params });
	}
}
