import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageBindingStrategy, maxLength, pattern, ReactiveFormConfig, required, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LanguageService, Note, ToastService } from '../../core';
import { UserEditService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component with form to edit user notes.
*/
@Component({
	selector: 'note-edit',
	templateUrl: './note-edit.component.html'
})
export class NoteEditComponent extends BaseComponent implements OnInit, OnDestroy {

	noteForm: RxFormGroup;
	noteList: Note[];
	isLoading: boolean;
	isSaving: boolean;
	ghostList = new Array(3).fill(1);

	private _userGuid: string;
	private _editUserSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private usrEditSrv: UserEditService,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.initForm();
		this._editUserSub = this.usrEditSrv.getEditUser().subscribe(user => {
			this._userGuid = user?.id;
			this.updateList();
		});
	}

	/**
	 * Initialize note insertion form.
	 */
	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.noteForm = <RxFormGroup>this.formBuilder.formGroup(NoteForm, { note: '' } as NoteForm);
	}

	/**
	 * Update note list.
	 */
	private updateList(): void {
		if (!!this._userGuid) {
			this.isLoading = true;
			this.usrEditSrv.getNoteList(this._userGuid).pipe(
				finalize(() => this.isLoading = false)
			).subscribe(list => this.noteList = list);
		}
	}

	/**
	 * Submit note form.
	 */
	insert(): void {
		if (this.noteForm.valid) {
			this.isSaving = true;
			this.usrEditSrv.insertNote(this._userGuid, this.noteForm.value.note).pipe(
				finalize(() => {
					this.isSaving = false;
					this.updateList();
				})
			).subscribe({
				next: () => this.toastSrv.showSuccess(this.labels['NOTE_EDIT_COMPONENT_INSERT_SUCCESS']),
				error: () => this.toastSrv.showError(this.labels['NOTE_EDIT_COMPONENT_INSERT_ERROR'])
			})
		}
	}

	ngOnDestroy() {
		this._editUserSub?.unsubscribe();
	}

}

/**
 * Note form model.
 */
class NoteForm {
	@required({ messageKey: 'VALIDATION_MESSAGE_REQUIRED_NOTE' })
	@maxLength({ value: 200, messageKey: 'VALIDATION_MESSAGE_MAXLENGTH_NOTE' })
	@pattern({ expression: { 'noSpecialChars': /^[^[\]<>]+$/ }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_NOTE" })
	note: string;
}
