import { Country } from './country';
import { Practice } from './practice';
import { Product } from './product';

export interface User {
	id: string;
	userName: string;
	firstName: string;
	lastName: string;
	birthDate: Date;
	status: string;
	distributor?: string;
	roles: string[];
	roleInfo: string;
	country: Country;
	products: Product[];
	hospitalOrCompany: string;
	address: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	officePhone: string;
	mobilePhone?: string;
	practice?: Practice;
	isMigratedTLHEX: boolean;
}

export const US_CODE: string = 'US';
export const SURGEON: string = 'Surgeon';
