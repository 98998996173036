import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './base-component';
import { ConfirmationComponent } from './confirmation';
import { DatepickerComponent, DaterangepickerComponent } from './datepicker';
import { ConvertDatePipe } from './pipe';

@NgModule({
	declarations: [
		BaseComponent,
		ConfirmationComponent,
		DatepickerComponent,
		ConvertDatePipe,
		DaterangepickerComponent
	],
	exports: [
		BaseComponent,
		DatepickerComponent,
		ConvertDatePipe,
		DaterangepickerComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbDatepickerModule
	],
	providers: []
})
export class SharedModule { }
