<form [formGroup]="editForm" *ngIf="isFormVisible">

  <div class="row">

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="userName">{{ labels.USER_FORM_COMPONENT_USERNAME }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="userName"
          placeholder="{{ labels.USER_FORM_COMPONENT_USERNAME }}" formControlName="userName"
          (input)="resetUserNameNotUnique()"
          [ngClass]="{'is-valid': editForm.controls.userName.valid && editForm.controls.userName.touched, 'is-invalid': editForm.controls.userName.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.userName.invalid">
          {{editForm.controls.userName["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="firstName">{{ labels.USER_FORM_COMPONENT_FIRSTNAME }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="firstName"
          placeholder="{{ labels.USER_FORM_COMPONENT_FIRSTNAME }}" formControlName="firstName"
          [ngClass]="{'is-valid': editForm.controls.firstName.valid && editForm.controls.firstName.touched, 'is-invalid': editForm.controls.firstName.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.firstName.invalid">
          {{editForm.controls.firstName["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="lastName">{{ labels.USER_FORM_COMPONENT_LASTNAME }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="lastName"
          placeholder="{{ labels.USER_FORM_COMPONENT_LASTNAME }}" formControlName="lastName"
          [ngClass]="{'is-valid': editForm.controls.lastName.valid && editForm.controls.lastName.touched, 'is-invalid': editForm.controls.lastName.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.lastName.invalid">
          {{editForm.controls.lastName["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="dateOfBirth">{{ labels.USER_FORM_COMPONENT_DATE_OF_BIRTH }}&emsp;*</label>
        <div class="input-group wrapper-calendar" #dateOfBirthTarget
          [ngClass]="{'is-valid': editForm.controls.dateOfBirth.valid && editForm.controls.dateOfBirth.touched, 'is-invalid': editForm.controls.dateOfBirth.invalid}">
          <i class="material-icons input-group-append icon-calendar" (click)="dateOfBirthPicker.toggle()">today</i>
          <input type="text" autocomplete="nope" class="form-control" id="dateOfBirth"
            placeholder="{{ labels.USER_FORM_COMPONENT_DATE_OF_BIRTH }}" formControlName="dateOfBirthFormat"
            (focus)="editForm.controls.dateOfBirth.markAsTouched()"
            [ngClass]="{'is-valid': editForm.controls.dateOfBirth.valid && editForm.controls.dateOfBirth.touched, 'is-invalid': editForm.controls.dateOfBirth.invalid}"
            readonly style="color: #fff" />
          <i class="material-icons input-group-append align-self-center md-18" (click)="clearDateOfBirth()">clear</i>
        </div>
        <div class="invalid-feedback" *ngIf="editForm.controls.dateOfBirth.invalid">
          {{editForm.controls.dateOfBirth["errorMessage"]}}</div>
      </div>
      <datepicker #dateOfBirthPicker [value]="editForm.value.dateOfBirth" (dateChanged)="updateDateOfBirth($event)"
        [positionTarget]="dateOfBirthTarget" placement="bottom-right" [maxDate]="maxDateOfBirth">
      </datepicker>
    </div>

  </div>

  <div class="row">

    <div class="col-2">
      <div class="form-group mb-0">
        <label>{{ labels.USER_FORM_COMPONENT_USER_ROLES }}&emsp;*</label>
        <div class="custom-control custom-checkbox w-100" *ngFor="let role of usrRoleOptions_1">
          <input type="checkbox" class="custom-control-input" id="{{ role  }}" name="role"
            [checked]="editForm.value.roles?.includes(role)" [disabled]="isUserRoleDisabled(role)"
            (change)="updateSelectedRoles(role)" />
          <label class="custom-control-label" for="{{ role  }}">{{ role }}</label>
        </div>
      </div>
    </div>

    <div class="col-2">
      <div class="form-group mb-0">
        <label>&nbsp;</label>
        <div class="custom-control custom-checkbox w-100" *ngFor="let role of usrRoleOptions_2">
          <input type="checkbox" class="custom-control-input" id="{{ role  }}" name="role"
            [checked]="editForm.value.roles?.includes(role)" (change)="updateSelectedRoles(role)" />
          <label class="custom-control-label" for="{{ role  }}">{{ role }}</label>
        </div>
      </div>
    </div>

    <div class="col-2">
      <div class="form-group mb-0">
        <label>&nbsp;</label>
        <div class="custom-control custom-checkbox w-100" *ngFor="let role of usrRoleOptions_3">
          <input type="checkbox" class="custom-control-input" id="{{ role  }}" name="role"
            [checked]="editForm.value.roles?.includes(role)" (change)="updateSelectedRoles(role)" />
          <label class="custom-control-label" for="{{ role  }}">{{ role }}</label>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group mb-0">
        <label>{{ labels.USER_FORM_COMPONENT_PRODUCTS }}</label>
        <div class="row">
          <div class="col-5">
            <div class="custom-control custom-checkbox" *ngFor="let prod of productDemoOptions">
              <input type="checkbox" class="custom-control-input" id="{{ prod.code }}" name="prod"
                [checked]="isProductSelected(prod.code)" [disabled]="isProductDisabled(prod)"
                (change)="updateSelectedProducts(prod)" />
              <label class="custom-control-label" for="{{ prod.code }}">{{ prod.description }}</label>
            </div>
          </div>
          <div class="col-5" style="padding-top: 2.8rem;">
            <div class="custom-control custom-checkbox" *ngFor="let prod of productProdOptions">
              <input type="checkbox" class="custom-control-input" id="{{ prod.code }}" name="prod"
                [checked]="isProductSelected(prod.code)" [disabled]="isProductDisabled(prod)"
                (change)="updateSelectedProducts(prod)" />
              <label class="custom-control-label" for="{{ prod.code }}">{{ prod.description }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row" style="margin-top: -2rem;" *ngIf="editForm.controls.roles.invalid">
    <div class="col">
      <div class="mb-0 form-group is-invalid">
        <div class="invalid-feedback">
          {{editForm.controls.roles["errorMessage"]}}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="form-group mb-0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="isMigratedTLHEX" formControlName="isMigratedTLHEX" />
          <label class="custom-control-label"
            for="isMigratedTLHEX">{{ labels.USER_FORM_COMPONENT_TLHEX_MIGRATED }}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-3" *ngIf="isHospitalVisible">
      <div class="form-group mb-0">
        <label class="mb-0" for="hospital">{{ labels.USER_FORM_COMPONENT_HOSPITAL }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="hospital"
          placeholder="{{ labels.USER_FORM_COMPONENT_HOSPITAL }}" formControlName="hospital"
          [ngClass]="{'is-valid': editForm.controls.hospital.valid && editForm.controls.hospital.touched, 'is-invalid': editForm.controls.hospital.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.hospital.invalid">
          {{editForm.controls.hospital["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3" *ngIf="!isHospitalVisible">
      <div class="form-group mb-0">
        <label class="mb-0" for="company">{{ labels.USER_FORM_COMPONENT_COMPANY }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="company"
          placeholder="{{ labels.USER_FORM_COMPONENT_COMPANY }}" formControlName="company"
          [ngClass]="{'is-valid': editForm.controls.company.valid && editForm.controls.company.touched, 'is-invalid': editForm.controls.company.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.company.invalid">
          {{editForm.controls.company["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3" *ngIf="isDistributorVisible">
      <div class="form-group mb-0">
        <label class="mb-0" for="distributor">{{ labels.USER_FORM_COMPONENT_DISTRIBUTOR }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="distributor"
          placeholder="{{ labels.USER_FORM_COMPONENT_DISTRIBUTOR }}" formControlName="distributor"
          [ngClass]="{'is-valid': editForm.controls.distributor.valid && editForm.controls.distributor.touched, 'is-invalid': editForm.controls.distributor.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.distributor.invalid">
          {{editForm.controls.distributor["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="roleInfo">{{ labels.USER_FORM_COMPONENT_ROLEINFO }}&emsp;*</label>
        <select id="roleInfo" autocomplete="nope" class="form-control custom-select" formControlName="roleInfo"
          [ngClass]="{'is-valid': editForm.controls.roleInfo.valid && editForm.controls.roleInfo.touched, 'is-invalid': editForm.controls.roleInfo.invalid}">
          <option [ngValue]="null">-</option>
          <option *ngFor="let role of roleInfoOptions" [ngValue]="role">{{ role?.value }}</option>
        </select>
        <div class="invalid-feedback" *ngIf="editForm.controls.roleInfo.invalid">{{
					editForm.controls.roleInfo["errorMessage"] }}</div>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="country">{{ labels.USER_FORM_COMPONENT_COUNTRY }}&emsp;*</label>
        <select id="country" autocomplete="nope" class="form-control custom-select" formControlName="country"
          [compareWith]="compareCountry" (change)="updateStateProvince()"
          [ngClass]="{'is-valid': editForm.controls.country.valid && editForm.controls.country.touched, 'is-invalid': editForm.controls.country.invalid}">
          <option [ngValue]="null">-</option>
          <option *ngFor="let country of countryOptions" [ngValue]="country">{{ country?.name }}</option>
        </select>
        <div class="invalid-feedback" *ngIf="editForm.controls.country.invalid">{{
					editForm.controls.country["errorMessage"] }}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0" *ngIf="isUSStateVisible; else provinceBlock">
        <label class="mb-0" for="stateProvince">{{ labels.USER_FORM_COMPONENT_STATE_PROVINCE }}&emsp;*</label>
        <select class="form-control custom-select" id="stateProvince" autocomplete="nope"
          formControlName="usStateProvince"
          [ngClass]="{'is-valid': editForm.controls.usStateProvince.valid && editForm.controls.usStateProvince.touched, 'is-invalid': editForm.controls.usStateProvince.invalid}">
          <option [ngValue]="null">-</option>
          <option *ngFor="let state of usStateOptions" [ngValue]="state">{{ state?.name }}</option>
        </select>
        <div class="invalid-feedback" *ngIf="editForm.controls.usStateProvince.invalid">
          {{editForm.controls.usStateProvince["errorMessage"]}}</div>
      </div>
      <ng-template #provinceBlock>
        <div class="form-group mb-0">
          <label class="mb-0" for="stateProvince">{{ labels.USER_FORM_COMPONENT_STATE_PROVINCE }}&emsp;*</label>
          <input type="text" autocomplete="nope" class="form-control" id="stateProvince"
            placeholder="{{ labels.ACCOUNT_COMPONENT_PROVINCE }}" formControlName="stateProvince"
            [ngClass]="{'is-valid': editForm.controls.stateProvince.valid && editForm.controls.stateProvince.touched, 'is-invalid': editForm.controls.stateProvince.invalid}" />
          <div class="invalid-feedback" *ngIf="editForm.controls.stateProvince.invalid">
            {{editForm.controls.stateProvince["errorMessage"]}}</div>
        </div>
      </ng-template>
    </div>

    <div class="col-5">
      <div class="form-group mb-0">
        <label class="mb-0" for="address">{{ labels.USER_FORM_COMPONENT_ADDRESS }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="address"
          placeholder="{{ labels.USER_FORM_COMPONENT_ADDRESS }}" formControlName="address"
          [ngClass]="{'is-valid': editForm.controls.address.valid && editForm.controls.address.touched, 'is-invalid': editForm.controls.address.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.address.invalid">
          {{editForm.controls.address["errorMessage"]}}</div>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="city">{{ labels.USER_FORM_COMPONENT_CITY }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="city"
          placeholder="{{ labels.USER_FORM_COMPONENT_CITY }}" formControlName="city"
          [ngClass]="{'is-valid': editForm.controls.city.valid && editForm.controls.city.touched, 'is-invalid': editForm.controls.city.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.city.invalid">
          {{editForm.controls.city["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="postalCode">{{ labels.USER_FORM_COMPONENT_POSTAL_CODE }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="postalCode"
          placeholder="{{ labels.USER_FORM_COMPONENT_POSTAL_CODE }}" formControlName="postalCode"
          [ngClass]="{'is-valid': editForm.controls.postalCode.valid && editForm.controls.postalCode.touched, 'is-invalid': editForm.controls.postalCode.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.postalCode.invalid">
          {{editForm.controls.postalCode["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="officePhone">{{ labels.USER_FORM_COMPONENT_OFFICE_PHONE }}&emsp;*</label>
        <input type="text" autocomplete="nope" class="form-control" id="officePhone"
          placeholder="{{ labels.USER_FORM_COMPONENT_OFFICE_PHONE }}" formControlName="officePhone"
          [ngClass]="{'is-valid': editForm.controls.officePhone.valid && editForm.controls.officePhone.touched, 'is-invalid': editForm.controls.officePhone.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.officePhone.invalid">
          {{editForm.controls.officePhone["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group mb-0">
        <label class="mb-0" for="mobilePhone">{{ labels.USER_FORM_COMPONENT_MOBILE_PHONE }}</label>
        <input type="text" autocomplete="nope" class="form-control" id="mobilePhone"
          placeholder="{{ labels.USER_FORM_COMPONENT_MOBILE_PHONE }}" formControlName="mobilePhone"
          [ngClass]="{'is-valid': editForm.controls.mobilePhone.valid && editForm.controls.mobilePhone.touched, 'is-invalid': editForm.controls.mobilePhone.invalid}" />
        <div class="invalid-feedback" *ngIf="editForm.controls.mobilePhone.invalid">
          {{editForm.controls.mobilePhone["errorMessage"]}}</div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="isPracticeVisible">

    <div class="row">

      <div class="col-3">
        <div class="form-group mb-0">
          <label class="mb-0" for="practiceType">{{ labels.USER_FORM_COMPONENT_PRACTICE_TYPE }}&emsp;*</label>
          <select type="text" autocomplete="nope" class="form-control custom-select" id="practiceType"
            formControlName="practiceType"
            [ngClass]="{'is-valid': editForm.controls.practiceType.valid && editForm.controls.practiceType.touched, 'is-invalid': editForm.controls.practiceType.invalid}">
            <option [ngValue]="null">-</option>
            <option *ngFor="let practiceType of practiceTypeOptions" [ngValue]="practiceType">{{ practiceType?.value
							}}
            </option>
          </select>
          <div class="invalid-feedback" *ngIf="editForm.controls.practiceType.invalid">{{
						editForm.controls.practiceType["errorMessage"] }}</div>
        </div>
      </div>

      <div class="col-3">
        <div class="form-group mb-0">
          <label class="mb-0" for="npi">{{ labels.USER_FORM_COMPONENT_NPI }}&emsp;*</label>
          <input type="text" autocomplete="nope" class="form-control" id="npi"
            placeholder="{{ labels.USER_FORM_COMPONENT_NPI }}" formControlName="npi"
            [ngClass]="{'is-valid': editForm.controls.npi.valid && editForm.controls.npi.touched, 'is-invalid': editForm.controls.npi.invalid}" />
          <div class="invalid-feedback" *ngIf="editForm.controls.npi.invalid">
            {{editForm.controls.npi["errorMessage"]}}</div>
        </div>
      </div>

      <div class="col-3">
        <label class="mb-0" for="stateLicense1">{{ labels.USER_FORM_COMPONENT_STATE_LICENSE_1 }}&emsp;*</label>
        <div class="multirow-input">
          <div class="form-group mb-0">
            <select type="text" autocomplete="nope" class="form-control custom-select" id="stateCode1"
              formControlName="stateCode1"
              [ngClass]="{'is-valid': editForm.controls.stateCode1.valid && editForm.controls.stateCode1.touched, 'is-invalid': editForm.controls.stateCode1.invalid}">
              <option [ngValue]="null">-</option>
              <option *ngFor="let usState of usStateOptions" [ngValue]="usState">{{ usState?.code }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="editForm.controls.stateCode1.invalid">{{
							editForm.controls.stateCode1["errorMessage"] }}</div>
          </div>
          <div class="form-group mb-0">
            <input type="text" autocomplete="nope" class="form-control" id="licenseNumber1"
              placeholder="{{ labels.USER_FORM_COMPONENT_LICENCE_NUMBER }}" formControlName="licenseNumber1"
              [ngClass]="{'is-valid': editForm.controls.licenseNumber1.valid && editForm.controls.licenseNumber1.touched, 'is-invalid': editForm.controls.licenseNumber1.invalid}" />
            <div class="invalid-feedback" *ngIf="editForm.controls.licenseNumber1.invalid">
              {{editForm.controls.licenseNumber1["errorMessage"]}}</div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <label class="mb-0" for="stateLicense2">{{ labels.USER_FORM_COMPONENT_STATE_LICENSE_2 }}</label>
        <div class="multirow-input">
          <div class="form-group mb-0">
            <select type="text" autocomplete="nope" class="form-control custom-select" id="stateCode2"
              formControlName="stateCode2"
              [ngClass]="{'is-valid': editForm.controls.stateCode2.valid && editForm.controls.stateCode2.touched, 'is-invalid': editForm.controls.stateCode2.invalid}">
              <option [ngValue]="null">-</option>
              <option *ngFor="let usState of usStateOptions" [ngValue]="usState">{{ usState?.code }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="editForm.controls.stateCode2.invalid">{{
							editForm.controls.stateCode2["errorMessage"] }}</div>
          </div>
          <div class="form-group mb-0">
            <input type="text" autocomplete="nope" class="form-control" id="licenseNumber2"
              placeholder="{{ labels.USER_FORM_COMPONENT_LICENCE_NUMBER }}" formControlName="licenseNumber2"
              [ngClass]="{'is-valid': editForm.controls.licenseNumber2.valid && editForm.controls.licenseNumber2.touched, 'is-invalid': editForm.controls.licenseNumber2.invalid}" />
            <div class="invalid-feedback" *ngIf="editForm.controls.licenseNumber2.invalid">
              {{editForm.controls.licenseNumber2["errorMessage"]}}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <label class="mb-0" for="stateLicense3">{{ labels.USER_FORM_COMPONENT_STATE_LICENSE_3 }}</label>
        <div class="multirow-input">
          <div class="form-group mb-0">
            <select type="text" autocomplete="nope" class="form-control custom-select" id="stateCode3"
              formControlName="stateCode3"
              [ngClass]="{'is-valid': editForm.controls.stateCode3.valid && editForm.controls.stateCode3.touched, 'is-invalid': editForm.controls.stateCode3.invalid}">
              <option [ngValue]="null">-</option>
              <option *ngFor="let usState of usStateOptions" [ngValue]="usState">{{ usState?.code }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="editForm.controls.stateCode3.invalid">{{
							editForm.controls.stateCode3["errorMessage"] }}</div>
          </div>
          <div class="form-group mb-0">
            <input type="text" autocomplete="nope" class="form-control" id="licenseNumber3"
              placeholder="{{ labels.USER_FORM_COMPONENT_LICENCE_NUMBER }}" formControlName="licenseNumber3"
              [ngClass]="{'is-valid': editForm.controls.licenseNumber3.valid && editForm.controls.licenseNumber3.touched, 'is-invalid': editForm.controls.licenseNumber3.invalid}" />
            <div class="invalid-feedback" *ngIf="editForm.controls.licenseNumber3.invalid">
              {{editForm.controls.licenseNumber3["errorMessage"]}}</div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <label class="mb-0" for="stateLicense4">{{ labels.USER_FORM_COMPONENT_STATE_LICENSE_4 }}</label>
        <div class="multirow-input">
          <div class="form-group mb-0">
            <select type="text" autocomplete="nope" class="form-control custom-select" id="stateCode4"
              formControlName="stateCode4"
              [ngClass]="{'is-valid': editForm.controls.stateCode4.valid && editForm.controls.stateCode4.touched, 'is-invalid': editForm.controls.stateCode4.invalid}">
              <option [ngValue]="null">-</option>
              <option *ngFor="let usState of usStateOptions" [ngValue]="usState">{{ usState?.code }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="editForm.controls.stateCode4.invalid">{{
							editForm.controls.stateCode4["errorMessage"] }}</div>
          </div>
          <div class="form-group mb-0">
            <input type="text" autocomplete="nope" class="form-control" id="licenseNumber4"
              placeholder="{{ labels.USER_FORM_COMPONENT_LICENCE_NUMBER }}" formControlName="licenseNumber4"
              [ngClass]="{'is-valid': editForm.controls.licenseNumber4.valid && editForm.controls.licenseNumber4.touched, 'is-invalid': editForm.controls.licenseNumber4.invalid}" />
            <div class="invalid-feedback" *ngIf="editForm.controls.licenseNumber4.invalid">
              {{editForm.controls.licenseNumber4["errorMessage"]}}</div>
          </div>
        </div>
      </div>

      <div class="col-3">
        <label class="mb-0" for="stateLicense5">{{ labels.USER_FORM_COMPONENT_STATE_LICENSE_5 }}</label>
        <div class="multirow-input">
          <div class="form-group mb-0">
            <select type="text" autocomplete="nope" class="form-control custom-select" id="stateCode5"
              formControlName="stateCode5"
              [ngClass]="{'is-valid': editForm.controls.stateCode5.valid && editForm.controls.stateCode5.touched, 'is-invalid': editForm.controls.stateCode5.invalid}">
              <option [ngValue]="null">-</option>
              <option *ngFor="let usState of usStateOptions" [ngValue]="usState">{{ usState?.code }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="editForm.controls.stateCode5.invalid">{{
							editForm.controls.stateCode5["errorMessage"] }}</div>
          </div>
          <div class="form-group mb-0">
            <input type="text" autocomplete="nope" class="form-control" id="licenseNumber5"
              placeholder="{{ labels.USER_FORM_COMPONENT_LICENCE_NUMBER }}" formControlName="licenseNumber5"
              [ngClass]="{'is-valid': editForm.controls.licenseNumber5.valid && editForm.controls.licenseNumber5.touched, 'is-invalid': editForm.controls.licenseNumber5.invalid}" />
            <div class="invalid-feedback" *ngIf="editForm.controls.licenseNumber5.invalid">
              {{editForm.controls.licenseNumber5["errorMessage"]}}</div>
          </div>
        </div>
      </div>

    </div>

  </ng-container>

  <div class="row">
    <div class="col-12 btn-container">

      <button type="button" class="btn btn-secondary" [disabled]="isSaving" (click)="resetForm()">
        {{ labels.USER_FORM_COMPONENT_CLEAR }}
      </button>

      <button type="button" class="btn btn-primary" [disabled]="!isSaveEnabled" (click)="update()">
        {{ labels.USER_FORM_COMPONENT_UPDATE }}
      </button>

    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12 d-flex justify-content-end">

      <button type="button" class="btn bg-success" [disabled]="!isActiveEnabled"
        (click)="changeStatus(userStatusEnum.Active)">
        {{ labels.USER_FORM_COMPONENT_ACTIVATE }}
      </button>

      <button type="button" class="btn bg-danger ml-3" [disabled]="!isDenyEnabled"
        (click)="changeStatus(userStatusEnum.Denied)">
        {{ labels.USER_FORM_COMPONENT_DENY }}
      </button>

      <button type="button" class="btn bg-warning ml-3" [disabled]="!isSuspendEnabled"
        (click)="changeStatus(userStatusEnum.Suspended)">
        {{ labels.USER_FORM_COMPONENT_SUSPEND }}
      </button>

    </div>
  </div>

</form>
