import { Component, OnInit } from '@angular/core';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { finalize } from 'rxjs/operators';
import { Country, DemoUser, LanguageService, Product, ToastService, compareCountry, convertToLocalDate } from '../../core';
import { DemoUserForm } from '../../models';
import { DemoUserService, filteredProducts } from '../../services';
import { BaseComponent, DateRange } from '../../shared';



/**
 * Component of the Demo user event creation form. 
 */
@Component({
	selector: 'demo-user-form',
	templateUrl: './demo-user-form.component.html',
	styles: [
	]
})
export class DemoUserFormComponent extends BaseComponent implements OnInit {

	demoUserForm: RxFormGroup;
	isUsersLoading: boolean;
	demoUserList: DemoUser[] = [];
	countryOptions: Country[];
	productOptions: Product[];
	isSaving: boolean;

	compareCountry = compareCountry;

	constructor(
		private langSrv: LanguageService,
		private demoSrv: DemoUserService,
		private formBuilder: RxFormBuilder,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.initForm();
		this.demoSrv.initDictionaries().subscribe(dics => {
			this.countryOptions = dics[0];
			this.productOptions = dics[1];
		});
		this.updateDemoUserList();
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.demoUserForm = <RxFormGroup>this.formBuilder.formGroup(DemoUserForm);
	}

	private updateDemoUserList(): void {
		this.isUsersLoading = true;
		this.demoUserList = [];
		this.demoSrv.getDemoUserList().pipe(
			finalize(() => this.isUsersLoading = false)
		).subscribe(list => this.demoUserList = list);
	}

	updateSelectedUsers(list: string[]): void {
		this.demoUserForm.controls.users.setValue([...list]);
	}

	/**
	* Update Plan Creation Date string format
	*/
	updateEventDateRange(range: DateRange): void {
		this.demoUserForm.controls.eventDateRange.setValue(range);
		this.demoUserForm.controls.startDate.setValue(range?.from);
		this.demoUserForm.controls.endDate.setValue(range?.to);
		const format = range ? `${convertToLocalDate(range.from, false)} - ${convertToLocalDate(range.to, false)}` : '';
		this.demoUserForm.controls.eventDateFormat.setValue(format);
		this.demoUserForm.controls.eventDateFormat.markAsTouched();
	}

	/**
	* Reset Event Date
	*/
	clearEventDate(): void {
		this.updateEventDateRange(null);
	}

	/**
	 * Update current selected product list.
	 */
	updateSelectedProducts(prod: Product): void {
		const currentProds = this.demoUserForm.value.products;
		const otherProds = filteredProducts(prod, currentProds);
		if (currentProds.some((p: Product) => p.code === prod.code)) {
			this.demoUserForm.controls.products.setValue([...otherProds]);
		} else {
			this.demoUserForm.controls.products.setValue([...otherProds, prod]);
		}
		this.demoUserForm.controls.products.markAsTouched();
	}

	/**
	 * Submit form to create new demo user event.
	 */
	submitForm(): void {
		if (this.demoUserForm.valid) {
			this.isSaving = true;
			this.demoSrv.submitForm(<DemoUserForm>this.demoUserForm.modelInstance).pipe(
				finalize(() => this.isSaving = false)
			).subscribe(
				() => {
					this.toastSrv.showSuccess(this.labels['DEMO_USER_FORM_COMPONENT_CONFIRM_SUCCESS']);
					this.updateDemoUserList();
					this.demoUserForm.reset();
					this.demoUserForm.markAsUntouched();
					this.demoSrv.updateRequestList();
				}, () => this.toastSrv.showError(this.labels['DEMO_USER_FORM_COMPONENT_CONFIRM_ERROR']),
			)
		}
	}

}
