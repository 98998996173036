import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RayRepository } from '../core';

@Injectable()
export class RayService {

	constructor(private rayRepo: RayRepository) { }

	/**
	* Wipe Ray model data of all plans
	*/
	wipeAllData(): Observable<void> {
		return this.rayRepo.wipeAllData();
	}
}
