import { Component, OnInit } from '@angular/core';
import { ToastService } from './toast.service';


/**
* Toast component to display feedback messages
*/
@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styles: [],
	host: { '[class.ngb-toasts]': 'true' }
})
export class ToastComponent implements OnInit {

	constructor(public toastService: ToastService) { }

	ngOnInit() {
	}

}
