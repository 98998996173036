import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LanguageService {

	private localeId: string;
	private _labels: any = {};
	private _validationMsg: any = {};

	constructor(private http: HttpClient) { }

	/**
	* Get labels
	*/
	getLabels(): any {
		return this._labels;
	}

	/**
	* Get validation messages
	*/
	getValidationMessages(): any {
		return this._validationMsg;
	}

	/**
	* Load labels and validation messages 
	*/
	loadLabels(): Promise<any> {
		return combineLatest([
			this.http.get("assets/labels.json"),
			this.http.get("assets/validation-messages.json")
		]).pipe(
			tap(([labels, validationMsg]) => {
				this._labels = labels;
				this._validationMsg = validationMsg
			})
		).toPromise();
	}

	/**
	* Load validation messages
	*/
	loadValidationMsg(): Observable<any> {
		return this.http.get("assets/validation-messages.json");
	}

	/**
	* Set locale and load angular locales file js
	*/
	initLocale(localName: string): Promise<any> {
		console.log('Loading locales: ' + localName);
		const locale = localName?.split('-')[0];
		return import(
			/* webpackInclude: /\.mjs$/ */
			`/node_modules/@angular/common/locales/${locale}.mjs`
		).then(locale => {
			this.localeId = localName;
			registerLocaleData(locale.default);
		}).catch(() => this.localeId = 'en-US');

	}

	/**
	* Get current locale
	*/
	getCurrentLocale(): string {
		return this.localeId;
	}

}
