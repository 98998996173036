export interface Country {
	name: string;
	code: string;
}



/**
 * Compare countries for drop down list.
 * @param c1
 * @param c2
 */
export function compareCountry(c1: Country, c2: Country): boolean {
	return c1 && c2 ? c1.code === c2.code : c1 === c2;
}
