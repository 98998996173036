import { Pipe, PipeTransform } from '@angular/core';
import { convertToLocalDate } from 'src/app/core';


/**
 * Pipe to convert Date in local format string
 */
@Pipe({
	name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

	transform(date: Date): string {
		return !!date ? convertToLocalDate(date) : null;
	}

}
