<div class="row">

	<div class="col-12">

		<div class="panel panel-thead" *ngIf="isLoading || requestList?.length > 0">
			<div class="row no-gutters">

				<div class="col no-link w-100">{{ labels.DEMO_EVENT_LIST_COMPONENT_EVENT_NAME }}</div>
				<div class="col no-link">{{ labels.DEMO_EVENT_LIST_COMPONENT_REQUESTOR }}</div>
				<div class="col no-link">{{ labels.DEMO_EVENT_LIST_COMPONENT_COUNTRY }}</div>
				<div class="col no-link">{{ labels.DEMO_EVENT_LIST_COMPONENT_START_DATE }}</div>
				<div class="col no-link">{{ labels.DEMO_EVENT_LIST_COMPONENT_END_DATE }}</div>
				<div class="col no-link">{{ labels.DEMO_EVENT_LIST_COMPONENT_PRODUCTS }}</div>
				<div class="col no-link"></div>

			</div>
		</div>

		<ng-template #loadedList>
			<div *ngIf="requestList?.length > 0">
				<div class="panel panel-tr" *ngFor="let req of requestList; let i = index">
					<div class="row no-gutters">

						<div class="col w-100">{{ req?.eventName }}</div>
						<div class="col">{{ req?.requestor }}</div>
						<div class="col">{{ req?.countryName }}</div>
						<div class="col">{{ req?.startDate | convertDate }}</div>
						<div class="col">{{ req?.endDate | convertDate }}</div>
						<div class="col">{{ req?.products }}</div>
						<div class="col">
							<i class="material-icons action" title="{{ labels.DEMO_EVENT_LIST_COMPONENT_DOWNLOAD }}"
								[class.disabled]="isDownloading" (click)="download(req?.requestGuid)">
								file_download
							</i>
						</div>

					</div>
				</div>
			</div>

			<div *ngIf="requestList?.length === 0">
				<span class="results">{{ labels.DEMO_EVENT_LIST_COMPONENT_NO_RESULTS }}</span>
			</div>
		</ng-template>

		<div *ngIf="isLoading; else loadedList" style="max-height: 24rem; overflow-x: auto; opacity: 0.4; line-height: 0;">
			<div class="panel panel-tr" *ngFor="let ghost of ghostList">
				<div class="row no-gutters">

					<div class="col w-100">
						<div class="ghost-line"></div>
					</div>
					<div class="col">
						<div class="ghost-line"></div>
					</div>
					<div class="col">
						<div class="ghost-line ghost-line-short"></div>
					</div>
					<div class="col">
						<div class="ghost-line ghost-line-short"></div>
					</div>
					<div class="col">
						<div class="ghost-line ghost-line-short"></div>
					</div>
					<div class="col">
						<div class="ghost-line"></div>
					</div>
					<div class="col"><i class="material-icons action">file_download</i></div>

				</div>
			</div>
		</div>

	</div>

</div>