import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserItem, LanguageService, SortFields, SortOption } from '../../core';
import { BaseComponent } from '../../shared';
import { SortableHeaderComponent, SortEvent } from '../sortable-header';


/**
* Component with table of user list.
*/
@Component({
	selector: 'user-table',
	templateUrl: './user-table.component.html',
	styles: [
	]
})
export class UserTableComponent extends BaseComponent implements OnInit, AfterViewInit {

	@Input() userList: AdminUserItem[] = [];
	@Input() isLoading: boolean;
	@Input() sortOrder: SortOption = {};
	@Output() onSortTable = new EventEmitter<SortOption>();
	@Output() onExportTable = new EventEmitter<void>();

	@ViewChildren(SortableHeaderComponent) headers: QueryList<SortableHeaderComponent>;
	activeColumn: string;
	fields = SortFields;

	ghostList = new Array(5).fill(1);

	constructor(
		private langSrv: LanguageService,
		private router: Router
	) {
		super(langSrv);
	}

	ngOnInit() { }

	ngAfterViewInit() {
		setTimeout(() => this.initHeaders());
	}

	/**
	* Init column sort order
	*/
	private initHeaders(): void {
		const header = this.headers.find(header => header.name == this.sortOrder?.name);
		if (header) {
			header.direction = this.sortOrder?.desc ? 'desc' : 'asc';
			this.activeColumn = this.sortOrder?.name;
		}
	}

	/**
	* Update column sort order
	*/
	onSort({ column, direction }: SortEvent) {
		this.headers.forEach(header => header.name !== column ? header.reset() : null);
		this.activeColumn = direction === 'asc' || direction === 'desc' ? column : null;
		const sortOrder: SortOption = this.activeColumn ? { name: <SortFields>this.activeColumn, desc: direction === 'desc' } : null;
		this.onSortTable.emit(sortOrder);
	}

	/**
	* Navigate to user editing page
	*/
	editUser(userId: string): void {
		this.router.navigate(['/user/edit/', userId]);
	}

	/**
	 * Trigger export action.
	 */
	export(): void {
		this.onExportTable.emit();
	}

}
