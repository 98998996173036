<div class="row">
  <div class="col-3 pr-5">

    <div class="form-group mb-0">
      <label for="email" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_EMAIL }}</label>
      <input type="text" autocomplete="nope" class="form-control" id="email"
        placeholder="{{ labels.USER_SEARCH_COMPONENT_EMAIL }}" [(ngModel)]="filter.email" />
    </div>

    <div class="form-group mb-0">
      <label for="first-name" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_FIRST_NAME }}</label>
      <input type="text" autocomplete="nope" class="form-control" id="first-name"
        placeholder="{{ labels.USER_SEARCH_COMPONENT_FIRST_NAME }}" [(ngModel)]="filter.firstName" />
    </div>

  </div>

  <div class="col-3 pr-5">

    <div class="form-group mb-0">
      <label for="last-name" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_LAST_NAME }}</label>
      <input type="text" autocomplete="nope" class="form-control" id="last-name"
        placeholder="{{ labels.USER_SEARCH_COMPONENT_LAST_NAME }}" [(ngModel)]="filter.lastName" />
    </div>

    <div class="form-group mb-0">
      <label for="country" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_COUNTRY }}</label>
      <select class="form-control custom-select" id="country" autocomplete="nope" [(ngModel)]="filter.country"
        [compareWith]="compareCountry">
        <option [ngValue]="undefined">-</option>
        <option *ngFor="let country of countryOptions" [value]="country?.code">{{ country?.name }}</option>
      </select>
    </div>

  </div>

  <div class="col-3 pr-5">

    <div class="form-group mb-0">
      <label for="role" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_ROLES }}</label>
      <select class="form-control custom-select" id="role" autocomplete="nope" [(ngModel)]="filter.role"
        [compareWith]="compareCountry">
        <option [ngValue]="undefined">-</option>
        <option *ngFor="let role of roleOptions" [value]="role">{{ role }}</option>
      </select>
    </div>

    <div class="form-group mb-0">
      <label for="roleInfo" class="mb-0">{{ labels.USER_SEARCH_COMPONENT_ROLE_INFO }}</label>
      <select class="form-control custom-select" id="roleInfo" autocomplete="nope" [(ngModel)]="filter.roleInfo"
        [compareWith]="compareCountry">
        <option [ngValue]="undefined">-</option>
        <option *ngFor="let roleInfo of roleInfoOptions" [value]="roleInfo">{{ roleInfo }}</option>
      </select>
    </div>

  </div>

  <div class="col-3">

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="all" [(ngModel)]="allStatus"
          (change)="updateAllStatus()" />
        <label class="custom-control-label" for="all">{{ labels.USER_SEARCH_COMPONENT_ALL_STATUS }}</label>
      </div>
    </div>

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="approved" [(ngModel)]="filter.approved" />
        <label class="custom-control-label" for="approved">{{ labels.USER_SEARCH_COMPONENT_APPROVED }}</label>
      </div>
    </div>

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="denied" [(ngModel)]="filter.denied" />
        <label class="custom-control-label" for="denied">{{ labels.USER_SEARCH_COMPONENT_DENIED }}</label>
      </div>
    </div>

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="active" [(ngModel)]="filter.active" />
        <label class="custom-control-label" for="active">{{ labels.USER_SEARCH_COMPONENT_ACTIVE }}</label>
      </div>
    </div>

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="suspended" [(ngModel)]="filter.suspended" />
        <label class="custom-control-label" for="suspended">{{ labels.USER_SEARCH_COMPONENT_SUSPENDED }}</label>
      </div>
    </div>

    <div class="form-group mb-0 pb-0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="unprocessed" [(ngModel)]="filter.unprocessed" />
        <label class="custom-control-label" for="unprocessed">{{ labels.USER_SEARCH_COMPONENT_UNPROCESSED }}</label>
      </div>
    </div>

  </div>
</div>
<div class="row">
  <div class="col-12">

    <button type="button" class="btn btn-primary" (click)="search()">{{ labels.USER_SEARCH_COMPONENT_SEARCH }}</button>
    <button type="button" class="btn btn-secondary ml-4"
      (click)="clear()">{{ labels.USER_SEARCH_COMPONENT_CLEAR }}</button>

  </div>
</div>
