import { convertToLocalDate, User } from "../core";

/**
 * Flattened user model.
 */
export interface UserFlat {

	status: string;

	userName: string;

	firstName: string;

	lastName: string;

	dateOfBirth: string;

	country: string;

	roles: string;

	roleInfo: string;

	practiceType: string;

	npi: string;

	stateCode1: string;

	licenseNumber1: string;

	stateCode2: string;

	licenseNumber2: string;

	stateCode3: string;

	licenseNumber3: string;

	stateCode4: string;

	licenseNumber4: string;

	stateCode5: string;

	licenseNumber5: string;

	distributor: string;

	hospitalOrCompany: string;

	address: string;

	city: string;

	stateProvince: string;

	postalCode: string;

	officePhone: string;

	mobilePhone: string;
}

export function flatUserMapper(user: User): UserFlat {
	if (!user) return null;
	return {
		status: user.status,

		userName: user.userName,

		firstName: user.firstName,

		lastName: user.lastName,

		dateOfBirth: convertToLocalDate(user.birthDate),

		country: user.country?.name,

		roles: user.roles?.join(', '),

		roleInfo: user.roleInfo,

		practiceType: user.practice?.type,

		npi: user.practice?.npi,

		stateCode1: user.practice?.l1StateCode,

		licenseNumber1: user.practice?.l1Number,

		stateCode2: user.practice?.l2StateCode,

		licenseNumber2: user.practice?.l2Number,

		stateCode3: user.practice?.l3StateCode,

		licenseNumber3: user.practice?.l3Number,

		stateCode4: user.practice?.l4StateCode,

		licenseNumber4: user.practice?.l4Number,

		stateCode5: user.practice?.l5StateCode,

		licenseNumber5: user.practice?.l5Number,

		distributor: user.distributor,

		hospitalOrCompany: decodeURI(user.hospitalOrCompany),

		address: decodeURI(user.address),

		city: user.city,

		stateProvince: user.stateProvince,

		postalCode: user.postalCode,

		officePhone: user.officePhone,

		mobilePhone: user.mobilePhone
	}
}