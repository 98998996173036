import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LanguageService, User, UserStatusType } from '../../core';
import { UserEditService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of user edit page.
*/
@Component({
	selector: 'user-edit',
	templateUrl: './user-edit.component.html'
})
export class UserEditComponent extends BaseComponent implements OnInit, OnDestroy {

	user: User;

	isPwdPanelDisabled: boolean = true;
	@ViewChild('acc') accordion!: NgbAccordion;

	private _editUserSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private router: Router,
		private usrEditSrv: UserEditService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this._editUserSub = this.usrEditSrv.getEditUser().subscribe(usr => {
			this.user = usr;
			this.updatePasswordPanel(this.user);
		});
	}

	private updatePasswordPanel(user: User): void {
		this.isPwdPanelDisabled = user?.status !== UserStatusType.Active;
		if (this.isPwdPanelDisabled) {
			this.accordion?.collapse('pwd-panel');
		}
	}

	/**
	* Navigate to user list page
	*/
	back(): void {
		this.router.navigate(['/user/list']);
	}

	ngOnDestroy() {
		if (this._editUserSub) {
			this._editUserSub.unsubscribe();
		}
		this.usrEditSrv.clearEditUser();
	}

}
