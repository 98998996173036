import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Event, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class MonitoringService {
	private readonly exceptionSeverityLevel = SeverityLevel.Warning;
	private readonly traceSeverityLevel = SeverityLevel.Information;
	private routerSubscription: Subscription;
	private appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: environment.insightKey,
		}
	});

	consoleError = console.error;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {
		const consoleLog = console.log;
		const consoleWarn = console.warn;
		const consoleError = console.error;
		console.log = (msg, params) => {
			params ? consoleLog(msg, params) : consoleLog(msg);
			this.logEvent(msg, params);
		};
		console.warn = (msg, params) => {
			params ? consoleWarn(msg, params) : consoleWarn(msg);
			this.logEvent(msg, params);
		};
		console.error = (msg, params) => {
			this.logError(msg, params);
		};
		this.appInsights.loadAppInsights();
		this.routerSubscription = this.router.events
			.pipe(filter(event => event instanceof ResolveEnd))
			.subscribe((res: Event) => {
				const event = res as ResolveEnd;
				const activatedComponent = this.getActivatedComponent(event.state.root);
				if (activatedComponent) {
					this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
				}
			});
	}

	private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
		if (snapshot.firstChild) {
			return this.getActivatedComponent(snapshot.firstChild);
		}

		return snapshot.component;
	}

	private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
		let path = '';
		if (snapshot.routeConfig) {
			path += snapshot.routeConfig.path;
		}
		if (snapshot.firstChild) {
			return path + this.getRouteTemplate(snapshot.firstChild);
		}
		return path;
	}

	private addGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
		if (!properties) {
			properties = {};
		}

		// add custom properties such as app version

		return properties;
	}

	public logPageView(name?: string, uri?: string) {
		this.appInsights.trackPageView({ name, uri });
	}

	public logEvent(message: string, properties?: { [key: string]: string }) {
		this.appInsights.trackTrace({ message, severityLevel: this.traceSeverityLevel }, this.addGlobalProperties(properties));
	}

	public logError(error: any, params?: any) {
		this.appInsights.trackException({ error, severityLevel: this.exceptionSeverityLevel });
		params ? this.consoleError(error, params) : this.consoleError(error);
	}

	public setAuthenticatedUserId(userId: string): void {
		this.appInsights.setAuthenticatedUserContext(userId);
	}

	public clearAuthenticatedUserId(): void {
		this.appInsights.clearAuthenticatedUserContext();
	}
}
