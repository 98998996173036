import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@ortho-next/auth-core';
import { first } from 'rxjs/operators';
import { LanguageService, ToastService } from 'src/app/core';
import { RayService, UserService } from 'src/app/services';
import { BaseComponent, ConfirmationComponent } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html',
	styles: []
})
export class NavbarComponent extends BaseComponent implements OnInit {

	buildVersion: string = environment.buildVer;
	isVersionVisible: boolean;
	isWipeBtnVisible: boolean;
	userName: string;

	constructor(
		private langSrv: LanguageService,
		private usrSrv: UserService,
		private authSrv: AuthService,
		private raySrv: RayService,
		private modalSrv: NgbModal,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.isVersionVisible = environment.name !== 'production';
		this.isWipeBtnVisible = environment.name !== 'production';
		this.usrSrv.getCurrentUser().pipe(first()).subscribe(user => this.userName = `${user?.firstName} ${user?.lastName}`);
	}

	/**
	* Open comfirmation modal before wipe ray data
	*/
	openWipeModal(): void {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['NAVBAR_COMPONENT_WIPE_COMFIRM_TITLE'],
				message: this.labels['NAVBAR_COMPONENT_WIPE_COMFIRM_MSG']
			};
			modalRef.result.then(() => this.wipeAllRayData()).catch(error => false);
		}
	}

	/**
	* Wipe Ray model data of all plans
	*/
	private wipeAllRayData(): void {
		this.raySrv.wipeAllData().subscribe(res => {
			this.toastSrv.showSuccess(this.labels['NAVBAR_COMPONENT_WIPE_SUCCESS_TITLE'])
		});
	}

	/**
	* Log out and redirect to login page
	*/
	logout(): void {
		this.authSrv.singleSignOut();
	}

}
