<h1 class="mb-5">{{ labels.PRODUCTS_EDIT_COMPONENT_TITLE }}</h1>

<form [formGroup]="productForm">

	<div class="row">

		<div class="col-3">
			<div class="form-group mb-0">
				<label for="country" class="mb-0">{{ labels.PRODUCT_EDIT_COMPONENT_COUNTRY }}</label>
				<select class="form-control custom-select" id="country" autocomplete="nope" formControlName="country"
					[compareWith]="compareCountry" (change)="updateProductList()"
					[ngClass]="{'is-valid': productForm.controls.country.valid && productForm.controls.country.touched, 'is-invalid': productForm.controls.country.invalid}">
					<option [ngValue]="null">-</option>
					<option *ngFor="let country of countryOptions" [ngValue]="country">{{ country?.name }}</option>
				</select>
				<div class="invalid-feedback" *ngIf="productForm.controls.country.invalid">
					{{productForm.controls.country["errorMessage"]}}
				</div>
			</div>
		</div>

		<div class="col-1"></div>

		<div class="col-6">
			<div class="form-group mb-0">
				<label>{{ labels.USER_FORM_COMPONENT_PRODUCTS }}</label>
				<div class="row">
					<div class="col-5">
						<div class="custom-control custom-checkbox" *ngFor="let prod of productDemoOptions">
							<input type="checkbox" class="custom-control-input" id="{{ prod.code }}" name="prod"
								[checked]="isProductSelected(prod.code)" [disabled]="isProductDisabled(prod)"
								(change)="updateSelectedProducts(prod)" />
							<label class="custom-control-label" for="{{ prod.code }}">{{ prod.description }}</label>
						</div>
					</div>
					<div class="col-5" style="padding-top: 2.8rem;">
						<div class="custom-control custom-checkbox" *ngFor="let prod of productProdOptions">
							<input type="checkbox" class="custom-control-input" id="{{ prod.code }}" name="prod"
								[checked]="isProductSelected(prod.code)" [disabled]="isProductDisabled(prod)"
								(change)="updateSelectedProducts(prod)" />
							<label class="custom-control-label" for="{{ prod.code }}">{{ prod.description }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-2">
			<button type="button" class="btn btn-primary" style="margin-top: 2rem;"
				[disabled]="productForm.invalid || isProdsLoading" (click)="submitForm()">
				{{ labels.PRODUCT_EDIT_COMPONENT_BTN_UPDATE }}
			</button>
		</div>

	</div>

</form>


<div class="mt-3 mb-3">
	<div class="row">
		<div class="col-lg-6 d-flex align-items-end">
			<span class="results">
				{{ countryProductList?.length ?? 0 }} {{ labels.PRODUCT_EDIT_COMPONENT_RESULTS }}
			</span>
		</div>
	</div>
</div>

<div class="panel panel-thead w-50">
	<div class="row no-gutters">

		<div class="col no-link">{{ labels.PRODUCT_EDIT_COMPONENT_HEADER_COUNTRY }}</div>
		<div class="col no-link">{{ labels.PRODUCT_EDIT_COMPONENT_HEADER_PRODUCT }}</div>

	</div>
</div>

<div class="w-50" *ngIf="isTableLoading; else loadedList" style="opacity: 0.4; line-height: 0;">
	<div class="panel panel-tr" *ngFor="let ghost of ghostList">
		<div class="row no-gutters">

			<div class="col">
				<div class="ghost-line"></div>
			</div>
			<div class="col">
				<div class="ghost-line"></div>
			</div>

		</div>
	</div>
</div>



<ng-template #loadedList>

	<div class="w-50">
		<div class="panel panel-tr" *ngFor="let countryProd of countryProductList; let i = index">
			<div class="row no-gutters">

				<div class="col">{{ countryName(i) }}</div>
				<div class="col">{{ countryProd.product.description }}</div>

			</div>
		</div>
	</div>

</ng-template>