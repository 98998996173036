import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DemoUserService } from '../services';
import { SharedModule } from '../shared';
import { DemoEventListComponent } from './demo-event-list';
import { DemoUserEditComponent } from './demo-user-edit';
import { DemoUserFormComponent } from './demo-user-form';
import { DemoUserListComponent } from './demo-user-list';

@NgModule({
	declarations: [
		DemoUserEditComponent,
		DemoUserListComponent,
		DemoUserFormComponent,
		DemoEventListComponent
	],
	exports: [
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule,
		NgbAccordionModule,
		SharedModule
	],
	providers: [
		DemoUserService
	]
})
export class DemoUsersModule { }
