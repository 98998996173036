import { choice, required } from "@rxweb/reactive-form-validators";
import { Country, Product } from "../core";


/**
 * Country Product Association form model.
 */
export class ProductForm {

	@choice({ minLength: 1 })
	products: Product[] = [];

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COUNTRY" })
	country: Country;
}