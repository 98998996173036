import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { AdminRoleGuard } from '../admin-role-guard';
import { ApproverEditComponent } from '../approvers';
import { ToastComponent, ToastService } from '../core';
import { DemoUserEditComponent } from '../demo-users';
import { ProductEditComponent } from '../products';
import { UserEditComponent, UserListComponent } from '../users';
import { ContainerTemplateComponent } from './container-template';
import { NavbarComponent } from './navbar';

const routes: Routes = [
	{
		path: '', component: ContainerTemplateComponent, children: [
			{ path: '', redirectTo: 'user/list', pathMatch: 'full' },
			{ path: 'user/list', component: UserListComponent },
			{ path: 'user/edit/:userId', component: UserEditComponent },
			{ path: 'approvers', component: ApproverEditComponent },
			{ path: 'demo-users', component: DemoUserEditComponent },
			{ path: 'products', component: ProductEditComponent },
		], canActivate: [AuthGuardWithForcedLogin, AdminRoleGuard]
	}
];

@NgModule({
	declarations: [
		ContainerTemplateComponent,
		NavbarComponent,
		ToastComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		NgbDropdownModule,
		NgbToastModule
	],
	exports: [
		ContainerTemplateComponent,
		RouterModule
	],
	providers: [
		ToastService
	]
})
export class TemplateModule { }
