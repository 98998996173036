import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule } from '@ortho-next/auth-core';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { ApproversModule } from './approvers';
import { ErrorsComponent, FallbackComponent, LanguageService, MonitoringErrorHandler, MonitoringService, RayRepository, UserRepository } from './core';
import { DemoUsersModule } from './demo-users';
import { ProductsModule } from './products';
import { RayService, UserService } from './services';
import { TemplateModule } from './template';
import { UsersModule } from './users';

export function initLabels(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.loadLabels();
	};
}
export function initLocale(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.initLocale(navigator.language);
	};
}

@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		TemplateModule,
		UsersModule,
		ApproversModule,
		DemoUsersModule,
		ProductsModule
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: MonitoringErrorHandler
		},
		MonitoringService,
		LanguageService,
		UserRepository,
		RayRepository,
		UserService,
		RayService,
		{
			provide: APP_INITIALIZER, useFactory: initLabels, deps: [LanguageService], multi: true
		},
		{
			provide: APP_INITIALIZER, useFactory: initLocale, deps: [LanguageService], multi: true
		},
		{
			provide: LOCALE_ID,
			useFactory: (langService: LanguageService) => langService.getCurrentLocale(),
			deps: [LanguageService]
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
