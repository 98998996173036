import { Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { ErrorsComponent, FallbackComponent } from './core';

export const APP_ROUTES: Routes = [
	{ path: 'error', component: ErrorsComponent },
	{
		path: '', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
	},
	{ path: '**', component: FallbackComponent, canActivate: [AuthGuardWithForcedLogin] }
];
