import { Injectable } from '@angular/core';


/**
* Configuration model for toast component
*/
export class ToastConfig {
	className?: string;
	delay?: number;
}


/**
* This services handles toast components
*/
@Injectable()
export class ToastService {

	toasts: any[] = [];

	constructor() { }

	/**
	* Show a generic toast
	*/
	show(message: string, options: ToastConfig) {
		this.toasts.push({ message, ...options });
	}

	/**
	* Show a success toast with message in input
	*/
	showSuccess(message: string, options: { delay?: number } = {}) {
		this.show(message, Object.assign(options, { className: 'bg-success' }))
	}

	/**
	* Show a standard success toast
	*/
	success() {
		this.showSuccess('Success!!!');
	}

	/**
	* Show an error toast with message in input
	*/
	showError(message: string, options: { delay?: number } = {}) {
		this.show(message, Object.assign(options, { className: 'bg-danger' }))
	}

	/**
	* Show a standard error toast
	*/
	error() {
		this.showError('Error!!!');
	}

	/**
	* Show a warning toast with message in input
	*/
	showWarning(message: string, options: { delay?: number } = {}) {
		this.show(message, Object.assign(options, { className: 'bg-warning' }))
	}

	/**
	* Show a standard warning toast
	*/
	warning() {
		this.showWarning('Warning!!!');
	}

	/**
	* Remove toast in input
	*/
	remove(toast: any) {
		this.toasts = this.toasts.filter(t => t != toast);
	}

}
