export const environment = {
  name: 'verification',
  production: true,
  insightKey: '48977294-3785-404d-8481-a3e779ab4a2b',
  domainPatientAPI: 'https://api-preprod21.orthonext.app/pat/v1',
  domainUserAPI: 'https://api-preprod21.orthonext.app/usr/v1',
  domainImageAPI: 'https://api-preprod21.orthonext.app/img/v1',
  domainRayAPI: 'https://api-preprod21.orthonext.app/ray/v1',
  b2cTenantName: 'orthonextb2cpreprod21',
  b2cTenantId: '3e8f01db-2f26-4513-ac95-86181ab138e1',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN',
  b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
  b2cClientId: 'fc4b5701-6d33-4eaa-9e12-f4d27e478a5c',
  b2cApplicationScope: 'openid profile https://orthonextb2cpreprod21.onmicrosoft.com/api/user_impersonation',
  appSSOutUriApi: 'https://api-preprod21.orthonext.app/services/appssouturi',
  idleTimeToEndSession: 1800,
  buildVer: 'OrthoNext-ARM FE VER21 CI-CD_24130.01',
  patplanSite: 'https://patpreprod21.orthonext.app/list/plans'
};
