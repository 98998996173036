<form [formGroup]="pwdForm" *ngIf="pwdForm">

  <div class="row">

    <div class="col-10">
      <div class="form-group mb-0 col-5 p-0"
        [ngClass]="{'is-valid': pwdForm.controls.password.valid && pwdForm.controls.password.touched, 'is-invalid': pwdForm.controls.password.invalid && pwdForm.controls.password.touched}">
        <label class="mb-0" for="password">{{ labels.PASSWORD_EDIT_COMPONENT_PASSWORD }}&emsp;*</label>
        <input type="password" autocomplete="nope" class="form-control" id="password" formControlName="password"
          placeholder="{{ labels.PASSWORD_EDIT_COMPONENT_PASSWORD }}"
          [ngClass]="{'is-valid': pwdForm.controls.password.valid && pwdForm.controls.password.touched, 'is-invalid': pwdForm.controls.password.invalid && pwdForm.controls.password.touched}" />
      </div>
      <div class="invalid-feedback" *ngIf="pwdForm.controls.password.invalid">
        {{pwdForm.controls.password["errorMessage"]}}</div>
    </div>

    <div class="col btn-container align-items-end">
      <button type="button" class="btn btn-primary" [disabled]="pwdForm.invalid || isSaving" (click)="change()">
        {{ labels.PASSWORD_EDIT_COMPONENT_CHANGE }}
      </button>
    </div>

  </div>

</form>
