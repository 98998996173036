<form [formGroup]="noteForm" *ngIf="noteForm">

  <div class="row">

    <div class="col-9">
      <div class="form-group p-0 mb-0">
        <label class="mb-0" for="note">{{ labels.NOTE_EDIT_COMPONENT_NEW_NOTE }}</label>
        <input type="text" autocomplete="nope" class="form-control" id="note" formControlName="note"
          placeholder="{{ labels.NOTE_EDIT_COMPONENT_NEW_NOTE }}"
          [ngClass]="{'is-valid': noteForm.controls.note.valid && noteForm.controls.note.touched, 'is-invalid': noteForm.controls.note.invalid && noteForm.controls.note.touched}" />
        <div class="invalid-feedback" *ngIf="noteForm.controls.note.invalid">
          {{noteForm.controls.note["errorMessage"]}}</div>
      </div>
    </div>

    <div class="col btn-container align-items-end">
      <button type="button" class="btn btn-primary" [disabled]="noteForm.invalid || isSaving" (click)="insert()">
        {{ labels.NOTE_EDIT_COMPONENT_INSERT }}
      </button>
    </div>

  </div>

</form>

<div class="row mt-5">

  <div class="col-4">
    <label class="mb-0">{{ labels.NOTE_EDIT_COMPONENT_HISTORY }}</label>
  </div>

  <!-- Note list -->
  <div class="col-8" *ngIf="!isLoading; else ghostPanel">
    <div class="note-panel">
      <div class="panel panel-tr" *ngFor="let note of noteList">
        <div class="row no-gutters">

          <div class="col">
            {{ note?.text }} <br />
            <span>{{ labels.NOTE_EDIT_COMPONENT_NAME }}</span> {{ note?.createdBy }}
            <span class="ml-4">{{ labels.NOTE_EDIT_COMPONENT_DATE }}</span> {{ note?.createdDate | convertDate }}
          </div>

        </div>
      </div>
    </div>
    <div class="results text-right">{{ noteList?.length ?? 0 }} {{ labels.NOTE_EDIT_COMPONENT_RESULTS }}</div>
  </div>

  <!-- Ghost list -->
  <ng-template #ghostPanel>
    <div class="col-8">
      <div class="note-panel" style="opacity: 0.4;">
        <div class="panel panel-tr" *ngFor="let ghost of ghostList">
          <div class="row no-gutters">

            <div class="col">
              <div class="ghost-line w-75"></div><br />
              <span>{{ labels.NOTE_EDIT_COMPONENT_NAME }}</span>
              <div class="ghost-line"></div>
              <span class="ml-4">{{ labels.NOTE_EDIT_COMPONENT_DATE }}</span>
              <div class="ghost-line"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-template>

</div>
