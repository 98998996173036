/**
* Convert date in string format according locale language
*/
export function convertToLocalDate(value: Date, UTCMode = true): string {
	if (!!value) {
		const date = new Date(value);
		const lang: string = navigator.language;
		const options = UTCMode ? { timeZone: 'UTC', numberingSystem: 'latn' } : { numberingSystem: 'latn' };
		const locale = new Intl.DateTimeFormat(lang, options).format(date);
		const raw = locale?.replace(/\u200f/g, ''); // remove rtl escape char
		return raw;
	}
	return null;
}

/**
* Convert date in string ISO format
*/
export function convertToISOString(date: Date): string {
	const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
	const isoString = utcDate.toISOString();
	return isoString;
}




const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

/**
* Convert ISO string in date
*/
export function parseIsoDateStrToDate(key: string, value: any) {
	if (typeof value === "string" && isoDateFormat.test(value)) {
		return new Date(value);
	}
	return value
}

/**
* Convert json date in object date
*/
export function revertJsonDate(date: any): any {
	const revertDate = JSON.parse(JSON.stringify(date), parseIsoDateStrToDate);
	return revertDate;
}