import { Component, OnInit } from '@angular/core';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { finalize } from 'rxjs/operators';
import { Country, CountryProduct, LanguageService, Product, ToastService, compareCountry } from '../../core';
import { ProductForm } from '../../models';
import { ProductService, filteredProducts, isProductDisabled } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Component of the page to handle products country association. 
 */
@Component({
	selector: 'app-product-edit',
	templateUrl: './product-edit.component.html'
})
export class ProductEditComponent extends BaseComponent implements OnInit {

	productForm: RxFormGroup;
	countryOptions: Country[];
	productDemoOptions: Product[] = [];
	productProdOptions: Product[] = [];

	countryProductList: CountryProduct[];
	isProdsLoading: boolean;
	isTableLoading: boolean;
	ghostList = new Array(5).fill(1);

	compareCountry = compareCountry;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private productsSrv: ProductService,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.initForm();
		this.productsSrv.initDictionaries().subscribe(res => {
			this.countryOptions = res[0];
			this.productDemoOptions = res[1].filter(p => p.demo);
			this.productProdOptions = res[1].filter(p => !p.demo);
			this.updateCountryProductList();
		});
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.productForm = <RxFormGroup>this.formBuilder.formGroup(ProductForm);
	}

	/**
	 * Get country name for country products table.
	 */
	countryName(index: number): string {
		return index === 0 || this.countryProductList[index].country.id !== this.countryProductList[index - 1].country.id ?
			this.countryProductList[index].country.value : ''
	}

	private get _currentProds(): Product[] {
		return this.productForm.value.products;
	}

	/**
	 * Check if product is selected.
	 */
	isProductSelected(prodCode: string): boolean {
		return this._currentProds.some(p => p.code === prodCode);
	}

	/**
	 * Check if product is disabled.
	 */
	isProductDisabled(prod: Product): boolean {
		if (this.productForm.controls.country.invalid || this.isProdsLoading) return true;
		return isProductDisabled(prod, this._currentProds);
	}

	/**
	 * Update current list of selected products.
	 */
	updateSelectedProducts(prod: Product): void {
		const otherProds = filteredProducts(prod, this._currentProds, this.productProdOptions);
		if (this.isProductSelected(prod.code)) {
			this.productForm.controls.products.setValue([...otherProds]);
		} else {
			this.productForm.controls.products.setValue([...otherProds, prod]);
		}
	}

	/**
	 * Update list of products.
	 */
	updateProductList(): void {
		const country = (this.productForm.value.country as Country);
		if (country) {
			this.isProdsLoading = true;
			this.productsSrv.getCountryProductList(country.code)
				.pipe(finalize(() => this.isProdsLoading = false))
				.subscribe(list => this.updateFormProducts(list?.map(cp => cp.product)));
		} else {
			this.updateFormProducts();
		}
	}

	private updateFormProducts(list?: Product[]): void {
		this.productForm.controls.products.setValue(list ?? []);
	}

	/**
	 * Update list of countrry products.
	 */
	updateCountryProductList(): void {
		this.isTableLoading = true;
		this.productsSrv.getCountryProductList()
			.pipe(finalize(() => this.isTableLoading = false))
			.subscribe(list => this.countryProductList = list);
	}

	/**
	 * Submit form to update country products association.
	 */
	submitForm(): void {
		if (this.productForm.valid && !this.isProdsLoading) {
			const valueForm = this.productForm.value as ProductForm;
			const countryCode = valueForm.country.code;
			const productCodes = valueForm.products.map(p => p.code);
			this.isProdsLoading = true;
			this.productsSrv.editCountryProducts(countryCode, productCodes).subscribe({
				next: () => {
					this.isProdsLoading = false;
					this.updateCountryProductList();
				},
				error: () => {
					this.isProdsLoading = false;
					this.toastSrv.showError(this.labels.PRODUCT_EDIT_COMPONENT_UPDATE_ERROR);
				}
			});
		}
	}

}
