import { ApiResponse, ApiResponseStatusCode } from "../core/repositories/models";


/**
* This service handles the base functionalities of services.
*/
export class BaseService {

	/**
	 * Handle and map response in ApiResponse wrapper.
	 * @param response Http response
	 */
	protected handleApiResponse<T>(response: ApiResponse<T>): T {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.UserNameNotUnique: throw new Error("UserNameNotUnique");
			default: throw new Error("Generic error");
		}
	}

}
