import { Alignment, Borders, FillPattern, Font, Workbook } from 'exceljs';
import { saveAs } from 'file-saver';



/**
 * Create a workbook.
 * List item fields must match the header keys.
 * @param headers List of headers with field name (i.e. key) and display name (i.e. header).
 * @param list List of row elements.
 * @param sheetName Title of sheet.
 * @returns The workbook created.
 */
export function createWorkBook(headers: { key: string, header: string }[], list: any[], sheetName?: string): Workbook {

	const workbook: Workbook = new Workbook();
	const sheet = workbook.addWorksheet(sheetName);

	// set headers
	sheet.columns = [...headers];

	// set header width
	const paddingRight = 10;
	sheet.columns.forEach(col => col.width = col.header.length + paddingRight);
	sheet.getRow(1).height = 25;

	// set header style
	const headerFont: Partial<Font> = { size: 13, bold: true, underline: false };
	const headerFill: FillPattern = { type: 'pattern', pattern: 'solid', fgColor: { argb: '63adf2' } };
	const headerBorder: Partial<Borders> = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
	const headerAlignment: Partial<Alignment> = { vertical: 'middle', horizontal: 'left' };
	sheet.getRow(1).eachCell({ includeEmpty: false }, cell => {
		cell.font = headerFont;
		cell.fill = headerFill;
		cell.border = headerBorder;
		cell.alignment = headerAlignment;
	});

	// add rows
	for (let item of list) {
		sheet.addRow(item);
	}

	// set cell style
	const cellAlignment: Partial<Alignment> = { horizontal: 'left' };
	sheet.getRows(2, list.length).forEach(row => row.eachCell(cell => cell.alignment = cellAlignment));

	return workbook;
}

/**
 * Save the input workbook with input file name.
 * @param workbook The workbook to save.
 * @param fileName The name of the file to save.
 */
export function saveWorkBook(workbook: Workbook, fileName: string): void {
	workbook.xlsx.writeBuffer().then(excelData => {
		const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const name = fileName ? `${fileName}.xlsx` : null;
		saveAs(blob, name);
	});

}