import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Approver, ApproverInsert, Country, countryMapper, DictionaryEnum, UserRepository } from '../core';
import { ApproverForm } from '../models';
import { BaseService } from './base.service';



/**
* This service handles approvers.
*/
@Injectable()
export class ApproverService extends BaseService {

	constructor(private usrRepo: UserRepository) {
		super();
	}

	/**
	 * Get list of countries.
	 */
	getCountryList(): Observable<Country[]> {
		return this.usrRepo.getDictionaries(DictionaryEnum.COUNTRY).pipe(
			map(res => this.handleApiResponse(res)),
			filter(dicList => !!dicList && dicList.length === 1),
			map(dicList => dicList[0]),
			map(dic => dic.values),
			map(list => list.map(item => countryMapper(item)))
		);
	}

	/**
	 * Create new approver from input form.
	 */
	createApprover(form: ApproverForm): Observable<void> {
		const approver: ApproverInsert = { countryCode: form.country?.code, email: form.email };
		return this.usrRepo.createApprover(approver).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Get list of approvers by country.
	 * @param countryCode Country code
	 * @returns List of approvers
	 */
	getApproverList(countryCode: string): Observable<Approver[]> {
		return this.usrRepo.getApproverList(countryCode).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Delete approver by guid id.
	 * @param approverGuid Approver guid id
	 */
	deleteApprover(approverGuid: string): Observable<void> {
		return this.usrRepo.deleteApprover(approverGuid).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

}
