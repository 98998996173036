import { compose, contains, email, maxDate, maxLength, minDate, oneOf, pattern, prop, required, RxwebValidators, trim } from "@rxweb/reactive-form-validators";
import { Country, PracticeType, Product, RoleInformationType, RoleTypeEnum, USA_CODE, USER_ROLE_LIST, USState } from "../core";

const specialChars: RegExp = /^[^<>%#&?,]+$/;


/**
 * User edit form model
 */
export class UserEditForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_USERNAME" })
	@maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_USERNAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_USERNAME" })
	@email({ messageKey: "VALIDATION_MESSAGE_FORMAT_USERNAME" })
	@contains({ value: "%", messageKey: "VALIDATION_MESSAGE_ALREADY_EXIST_USERNAME", conditionalExpression: (x: UserEditForm) => x.userNameNotUnique == true })
	@trim()
	userName: string;

	@prop({ defaultValue: false })
	userNameNotUnique?: boolean;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_FIRST_NAME" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_FIRST_NAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_FIRST_NAME" })
	@trim()
	firstName: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LAST_NAME" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LAST_NAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LAST_NAME" })
	@trim()
	lastName: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DATE_OF_BIRTH" })
	@maxDate({ value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), operator: '<=', messageKey: "VALIDATION_MESSAGE_MAXDATE_DATE_OF_BIRTH" })
	@minDate({ value: new Date(1899, 12, 1), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH" })
	dateOfBirth: Date;

	@prop()
	dateOfBirthFormat: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COUNTRY" })
	country: Country;

	@oneOf({ matchValues: USER_ROLE_LIST, messageKey: "VALIDATION_MESSAGE_REQUIRED_ROLES" })
	roles: string[];

	@prop()
	roleType: RoleTypeEnum;

	@prop()
	products: Product[];

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ROLE_INFO" })
	roleInfo: RoleInformationType;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PRACTICE_TYPE", conditionalExpression: (x: UserEditForm) => x.country?.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	practiceType: PracticeType;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_NPI", conditionalExpression: (x: UserEditForm) => x.country?.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	@compose({ validators: [RxwebValidators.digit(), RxwebValidators.minLength({ value: 10 }), RxwebValidators.maxLength({ value: 10 })], messageKey: "VALIDATION_MESSAGE_DIGIT_NPI" })
	@trim()
	npi: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserEditForm) => x.country?.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	stateCode1: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserEditForm) => x.country?.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	@trim()
	@trim()
	licenseNumber1: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserEditForm) => x.licenseNumber2 })
	stateCode2: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserEditForm) => x.stateCode2 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	@trim()
	licenseNumber2: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserEditForm) => x.licenseNumber3 })
	stateCode3: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserEditForm) => x.stateCode3 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	@trim()
	licenseNumber3: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserEditForm) => x.licenseNumber4 })
	stateCode4: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserEditForm) => x.stateCode4 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	@trim()
	licenseNumber4: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserEditForm) => x.licenseNumber5 })
	stateCode5: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserEditForm) => x.stateCode5 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	@trim()
	licenseNumber5: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DISTRIBUTOR", conditionalExpression: (x: UserEditForm) => x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_DISTRIBUTOR" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_DISTRIBUTOR" })
	@trim()
	distributor: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_HOSPITAL", conditionalExpression: (x: UserEditForm) => x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_HOSPITAL", conditionalExpression: (x: UserEditForm) => typeof (x.hospital) == "string" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_HOSPITAL" })
	@trim()
	hospital: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COMPANY", conditionalExpression: (x: UserEditForm) => x.roleType == RoleTypeEnum.OTHERS })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_COMPANY", conditionalExpression: (x: UserEditForm) => typeof (x.company) == "string" })
	@trim()
	company: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ADDRESS" })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_ADDRESS" })
	@trim()
	address: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_CITY" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_CITY" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_CITY" })
	@trim()
	city: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: UserEditForm) => x.country == null || x.country?.code != USA_CODE })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PROVINCE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PROVINCE" })
	@trim()
	stateProvince: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: UserEditForm) => x.country == null || x.country?.code == USA_CODE })
	usStateProvince: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_POSTAL_CODE" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_POSTAL_CODE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_POSTAL_CODE" })
	@trim()
	postalCode: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PHONE" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PHONE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PHONE" })
	@trim()
	officePhone: string;

	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_MOBILE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_MOBILE" })
	@trim()
	mobilePhone: string;

	@prop()
	isMigratedTLHEX: boolean;
}