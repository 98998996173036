export interface UserStatus {
	/**
	 * User Guid Id.
	 */
	id: string;
	/**
	 * New user status to update.
	 */
	status: UserStatusType;
}

export enum UserStatusType {
	Unprocessed = "Unprocessed",
	Approved = "Approved",
	Denied = "Denied",
	Active = "Active",
	Suspended = "Suspended"
}