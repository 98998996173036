import { Component, EventEmitter, Input, Output } from '@angular/core';


export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };
export interface SortEvent {
  column: string;
  direction: SortDirection;
}


/**
* Table header component to sort related column
*/
@Component({
  selector: 'sortable-header',
  templateUrl: './sortable-header.component.html',
  styles: [],
  host: {
    '(click)': 'rotate()'
  }
})
export class SortableHeaderComponent {

  @Input() name: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  constructor() { }

  /**
  * Emit column name and sort order when click on header 
  */
  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.name, direction: this.direction });
  }

  /**
  * Reset sort order of header
  */
  reset() {
    this.direction = '';
  }

}
