import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@ortho-next/auth-core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LanguageService } from './core';
import { ConfirmationComponent } from './shared';


@Injectable({ providedIn: 'root' })
export class AdminRoleGuard implements CanActivate {

	constructor(
		private langSrv: LanguageService,
		private authService: AuthService,
		private modalSrv: NgbModal
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.authService.isDoneLoading$.pipe(
			filter(() => this.authService.hasValidToken()),
			map(() => this.authService.roles.indexOf('Admin') !== -1),
			tap(isAdmin => !isAdmin && this.openUnauthorizedModal())
		);
	}

	openUnauthorizedModal(): void {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.langSrv.getLabels()['UNAUTHORIZED_MODAL_TITLE'],
				message: this.langSrv.getLabels()['UNAUTHORIZED_MODAL_MSG'],
				confirmBtn: this.langSrv.getLabels()['UNAUTHORIZED_MODAL_CONFIRM_BTN'],
				isCancelBtn: false
			};
			modalRef.result.then(() => this.authService.logout()).catch(error => false);
		}
	}

}