import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared';
import { LanguageService } from '../../core';

@Component({
  selector: 'container-template',
  templateUrl: './container-template.component.html'
})
export class ContainerTemplateComponent extends BaseComponent implements OnInit {
  constructor(private langSrv: LanguageService) {
    super(langSrv);
  }

  ngOnInit() {  }
}
