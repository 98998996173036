<div class="mt-3 mb-3">
  <div class="row">
    <div class="col-lg-6 d-flex align-items-end">
      <span class="results">{{ userList?.length ?? 0 }} {{ labels.USER_TABLE_COMPONENT_RESULTS }}</span>
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <button type="button" class="btn btn-success" (click)="export()">{{ labels.USER_TABLE_COMPONENT_EXPORT }}</button>
    </div>
  </div>
</div>

<div class="panel panel-thead">
  <div class="row no-gutters">

    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_STATUS }}</div>
    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_EMAIL }}</div>
    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_FIRST_NAME }}</div>
    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_LAST_NAME }}</div>
    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_ROLE }}</div>
    <div class="col" [ngClass]="{'active': activeColumn === fields.requestDate }">
      <sortable-header [name]="fields.requestDate" (sort)="onSort($event)">
        {{ labels.USER_TABLE_COMPONENT_REQUEST_DATE }}
      </sortable-header>
    </div>
    <div class="col no-link">{{ labels.USER_TABLE_COMPONENT_COUNTRY }}</div>
    <div class="col no-link"></div>

  </div>
</div>

<div *ngIf="isLoading; else loadedList" style="max-height: 39rem; overflow-x: auto; opacity: 0.4; line-height: 0;">
  <div class="panel panel-tr" *ngFor="let ghost of ghostList">
    <div class="row no-gutters">

      <div class="col">
        <div class="ghost-circle"></div>
      </div>
      <div class="col">
        <div class="ghost-line"></div>
      </div>
      <div class="col">
        <div class="ghost-line ghost-line-short"></div>
      </div>
      <div class="col">
        <div class="ghost-line ghost-line-short"></div>
      </div>
      <div class="col">
        <div class="ghost-line ghost-line-short"></div>
      </div>
      <div class="col">
        <div class="ghost-line ghost-line-short"></div>
      </div>
      <div class="col">
        <div class="ghost-line ghost-line-short"></div>
      </div>
      <div class="col">
        <i class="material-icons action">assignment_ind</i>
      </div>

    </div>
  </div>
</div>

<ng-template #loadedList>
  <div style="max-height: 39rem; overflow-x: auto;">
    <div class="panel panel-tr" *ngFor="let usr of userList">
      <div class="row no-gutters">

        <div class="col">
          <div class="user-holder" [ngClass]="usr?.status | lowercase" [title]="usr?.status">
            <span>{{ usr?.status | uppercase | slice:0:2 }}</span>
          </div>
        </div>
        <div class="col">
          {{usr?.userName}}
        </div>
        <div class="col">
          {{usr?.firstName}}
        </div>
        <div class="col">
          {{usr?.lastName}}
        </div>
        <div class="col">
          {{usr?.roles?.join(', ')}}
        </div>
        <div class="col">
          {{usr?.requestDate | convertDate }}
        </div>
        <div class="col">
          {{usr?.country?.name}}
        </div>
        <div class="col">
          <i class="material-icons action" title="{{ labels.USER_TABLE_COMPONENT_EDIT_USER }}"
            (click)="editUser(usr.id)">assignment_ind
          </i>
        </div>

      </div>
    </div>
  </div>
</ng-template>
