import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { BaseComponent } from '../../shared';



/**
 * Component of the page to handle demo users. 
 */
@Component({
	selector: 'demo-user-edit',
	templateUrl: './demo-user-edit.component.html',
	styles: []
})
export class DemoUserEditComponent extends BaseComponent implements OnInit {

	constructor(
		private langSrv: LanguageService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
	}

}
