import { Country } from './country';

export class Lookup {
	id: number;
	value: string;
	otherInfo?: string;
}

export class LegalDocumentType extends Lookup { }

export class DocumentItemType extends Lookup { }

export const countryMapper = (lookup: Lookup): Country => {
	return {
		name: lookup.value,
		code: lookup.otherInfo
	};
}

export class USState {
	id: number;
	name: string;
	code: string;
}

export const usStateMapper = (lookup: Lookup): USState => {
	return {
		id: lookup.id,
		name: lookup.otherInfo,
		code: lookup.value
	}
}

export interface Role extends Lookup { }

export class RoleInformationType extends Lookup { }

export class PracticeType extends Lookup { }

export const USA_CODE: string = 'US';

export const SURGEON_CODE: string = 'Surgeon';

export enum RoleTypeEnum {
	HCP = 'HCP',
	OTHERS = 'OTHERS'
}

export enum DictionaryEnum {
	COUNTRY = 'Country',
	ROLE = 'Role',
	ROLE_INFORMATION_TYPE = 'RoleInformationType',
	PRACTICE_TYPE = 'PracticeType',
	US_STATE = 'USState',
	LEGAL_DOCUMENT_TYPE = 'LegalDocumentType',
	DOCUMENT_ITEM_TYPE = 'DocumentItemType'
}

export const USER_DICTIONARY_LIST: string = DictionaryEnum.COUNTRY + ',' + DictionaryEnum.ROLE + ',' + DictionaryEnum.ROLE_INFORMATION_TYPE + ',' + DictionaryEnum.PRACTICE_TYPE + ',' + DictionaryEnum.US_STATE;



export enum UserRoles {
	ADMIN = 'Admin',
	CUSTOMER_CARE = 'CustomerCare',
	ORTHOFIX_USER = 'Orthofix User',
	SURGEON = 'Surgeon',
	SALES = 'Sales',
	MARKETING = 'Marketing',
	DEMO_USER = 'DemoUser'
}
export const USER_ROLE_LIST: string[] = [...Object.values(UserRoles)];