import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminNoteInsert, convertToISOString, convertToLocalDate, Country, countryMapper, Dictionary, DictionaryEnum, Note, Practice, PracticeType, Product, revertJsonDate, Role, RoleInformationType, RoleTypeEnum, SURGEON, US_CODE, User, UserPassword, UserRepository, UserStatus, UserStatusType, USState, usStateMapper } from '../core';
import { UserEditForm } from '../models';
import { BaseService } from './base.service';
import { orderedProducts } from './product.service';


/**
* This service handles user edit form 
*/
@Injectable()
export class UserEditService extends BaseService {

	private _editUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	private _countries: Country[] = [];
	private _usStates: USState[] = [];
	private _roles: Role[] = [];
	private _roleInfoTypes: RoleInformationType[] = [];
	private _practiceTypes: PracticeType[] = [];
	private _products: Product[] = [];

	constructor(private usrRepo: UserRepository) {
		super();
	}

	/**
	* Initalize dictionaries from repository.
	*/
	initDictionaries(): Observable<boolean> {
		if (this._countries.length > 0 && this._usStates.length > 0 && this._roleInfoTypes.length > 0 && this._practiceTypes.length > 0) {
			return of(true);
		}
		return combineLatest([this.getDictionaries(), this.getProductList()]).pipe(
			filter(([dics, prods]) => !!dics?.length && !!prods?.length),
			tap(([dics, prods]) => {
				this._countries = dics.find(x => x.name == DictionaryEnum.COUNTRY).values.map(country => countryMapper(country)) ?? [];
				this._usStates = dics.find(x => x.name == DictionaryEnum.US_STATE).values.map(state => usStateMapper(state)) ?? [];
				this._roles = dics.find(x => x.name == DictionaryEnum.ROLE).values ?? [];
				this._roleInfoTypes = dics.find(x => x.name == DictionaryEnum.ROLE_INFORMATION_TYPE).values ?? [];
				this._practiceTypes = dics.find(x => x.name == DictionaryEnum.PRACTICE_TYPE).values ?? [];
				this._countries.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
				this._usStates.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
				this.initProducts(prods);
			}),
			map(list => true)
		);
	}

	private initProducts(prods: Product[]): void {
		this._products = orderedProducts(prods);
	}

	getCountries() {
		return this._countries;
	}

	getUSStates() {
		return this._usStates;
	}

	getRoles() {
		return this._roles;
	}

	getRoleInfoTypes() {
		return this._roleInfoTypes;
	}

	getPracticeTypes() {
		return this._practiceTypes;
	}

	getProducts() {
		return this._products;
	}

	/**
	* Get current edit user.
	*/
	getEditUser(): Observable<User> {
		return this._editUser$.asObservable();
	}

	/**
	* Clear edit user.
	*/
	clearEditUser(): void {
		return this._editUser$.next(null);
	}

	/**
	* Load edit user from repository by user id.
	*/
	loadEditUser(id: string): Observable<User> {
		return this.usrRepo.getUser(id).pipe(
			map(res => this.handleApiResponse(res)),
			filter(user => !!user),
			tap(user => this._editUser$.next(user))
		);
	}

	/**
	* Get edit form model by user id
	*/
	getEditForm(userId: string): Observable<UserEditForm> {
		const userObs = this._editUser$.value?.id === userId ? this.getEditUser() : this.loadEditUser(userId);
		return userObs.pipe(map(user => this.editFormMapper(user)));
	}

	/**
	* Submit edit form and update user
	*/
	submitForm(form: UserEditForm): Observable<User> {
		const user: User = this.editUserMapper(form);
		return this.usrRepo.editUser(user).pipe(
			map(res => this.handleApiResponse(res)),
			switchMap(() => this.loadEditUser(user?.id))
		);
	}

	private editFormMapper = (user: User): UserEditForm => {
		if (!user) return null;
		const isSurgeon = user.roles?.includes(SURGEON);
		return {
			userName: user.userName,
			firstName: user.firstName,
			lastName: user.lastName,
			dateOfBirth: revertJsonDate(user.birthDate),
			dateOfBirthFormat: convertToLocalDate(revertJsonDate(user.birthDate)),
			country: user.country,
			hospital: isSurgeon ? user.hospitalOrCompany : null,
			company: !isSurgeon ? decodeURI(user.hospitalOrCompany) : null,
			distributor: user.distributor,
			city: user.city,
			address: decodeURI(user.address),
			postalCode: user.postalCode,
			officePhone: user.officePhone,
			mobilePhone: user.mobilePhone,
			stateProvince: !this.isUnitedStates(user.country) ? user.stateProvince : null,
			usStateProvince: this.isUnitedStates(user.country) ? this.getUsStateByName(user.stateProvince) : null,
			products: user.products,
			roles: user.roles,
			isMigratedTLHEX: user.isMigratedTLHEX,
			roleInfo: this.getRoleInfoByValue(user.roleInfo),
			roleType: isSurgeon ? RoleTypeEnum.HCP : RoleTypeEnum.OTHERS,
			practiceType: user.practice ? this.getPracticeTypeByValue(user.practice.type) : null,
			npi: user.practice ? user.practice.npi : null,
			stateCode1: user.practice ? this.getUsStateByCode(user.practice.l1StateCode) : null,
			licenseNumber1: user.practice ? user.practice.l1Number : null,
			stateCode2: user.practice ? this.getUsStateByCode(user.practice.l2StateCode) : null,
			licenseNumber2: user.practice ? user.practice.l2Number : null,
			stateCode3: user.practice ? this.getUsStateByCode(user.practice.l3StateCode) : null,
			licenseNumber3: user.practice ? user.practice.l3Number : null,
			stateCode4: user.practice ? this.getUsStateByCode(user.practice.l4StateCode) : null,
			licenseNumber4: user.practice ? user.practice.l4Number : null,
			stateCode5: user.practice ? this.getUsStateByCode(user.practice.l5StateCode) : null,
			licenseNumber5: user.practice ? user.practice.l5Number : null
		}
	}

	private editUserMapper(form: UserEditForm): User {
		if (!form) return null;
		const currentUser = this._editUser$.value;
		const isSurgeon = form.roles?.includes(SURGEON);
		return Object.assign({}, currentUser, {
			userName: form.userName,
			firstName: form.firstName,
			lastName: form.lastName,
			birthDate: convertToISOString(form.dateOfBirth),
			products: [...form.products],
			roles: [...form.roles],
			isMigratedTLHEX: form.isMigratedTLHEX,
			roleInfo: form.roleInfo?.value,
			hospitalOrCompany: isSurgeon ? form.hospital : encodeURI(form.company),
			distributor: isSurgeon ? form.distributor : null,
			country: form.country,
			stateProvince: this.isUnitedStates(form.country) ? form.usStateProvince?.name : form.stateProvince,
			address: encodeURI(form.address),
			city: form.city,
			postalCode: form.postalCode,
			officePhone: form.officePhone,
			mobilePhone: form.mobilePhone,
			practice: isSurgeon && this.isUnitedStates(form.country) ? <Practice>{
				type: form.practiceType?.value,
				npi: form.npi,
				l1StateCode: form.stateCode1?.code,
				l1Number: form.licenseNumber1,
				l2StateCode: form.stateCode2?.code,
				l2Number: form.licenseNumber2,
				l3StateCode: form.stateCode3?.code,
				l3Number: form.licenseNumber3,
				l4StateCode: form.stateCode4?.code,
				l4Number: form.licenseNumber4,
				l5StateCode: form.stateCode5?.code,
				l5Number: form.licenseNumber5
			} : null
		});
	}

	private isUnitedStates(country: Country): boolean {
		return country && country.code === US_CODE;
	}

	private getUsStateByName(name: string): USState {
		return this._usStates.find(state => state.name === name);
	}

	private getUsStateByCode(code: string): USState {
		return this._usStates.find(state => state.code === code);
	}

	private getRoleInfoByValue(value: string): RoleInformationType {
		return this._roleInfoTypes.find(role => role.value === value);
	}

	private getPracticeTypeByValue(value: string): PracticeType {
		return this._practiceTypes.find(type => type.value === value);
	}

	private getDictionaries(): Observable<Dictionary[]> {
		return this.usrRepo.getDictionaries().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Change user status.
	 * @param userId User id to update 
	 * @param status New user status to update
	 */
	changeUserStatus(userId: string, status: UserStatusType): Observable<boolean> {
		const userStatus: UserStatus = { id: userId, status: status };
		return this.usrRepo.changeUserStatus(userStatus).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Change user password.
	 * @param userName User name 
	 * @param password New user password to update
	 */
	changeUserPassword(userName: string, password: string): Observable<void> {
		const userPassword: UserPassword = { userName: userName, password: password };
		return this.usrRepo.changeUserPassword(userPassword).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Insert note.
	 * @param userGuid User Guid 
	 * @param text Note text to insert
	 */
	insertNote(userGuid: string, text: string): Observable<void> {
		const note: AdminNoteInsert = { userGuid: userGuid, text: text };
		return this.usrRepo.insertNote(note).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Get note list.
	 * @param userGuid User Guid 
	 */
	getNoteList(userGuid: string): Observable<Note[]> {
		return this.usrRepo.getNoteList(userGuid).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Get product list.
	 */
	getProductList(): Observable<Product[]> {
		return this.usrRepo.getProductList().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

}