<form [formGroup]="demoUserForm">

	<div class="row">

		<div class="col-6">

			<demo-user-list [isLoading]="isUsersLoading" [userList]="demoUserList" (onSelect)="updateSelectedUsers($event)">
			</demo-user-list>

			<div class="form-group mb-0" [ngClass]="{'is-invalid': demoUserForm.controls.users.invalid}">
				<div class="invalid-feedback" *ngIf="demoUserForm.controls.users.invalid">
					{{demoUserForm.controls.users["errorMessage"]}}</div>
			</div>

		</div>

		<div class="col-1"></div>

		<div class="col-5">

			<div class="form-group mb-0">
				<label class="mb-0" for="eventName">{{ labels.DEMO_USER_FORM_COMPONENT_EVENT_NAME }}&emsp;*</label>
				<input type="text" autocomplete="nope" class="form-control" id="eventName"
					placeholder="{{ labels.DEMO_USER_FORM_COMPONENT_EVENT_NAME }}" formControlName="eventName"
					[ngClass]="{'is-valid': demoUserForm.controls.eventName.valid && demoUserForm.controls.eventName.touched, 'is-invalid': demoUserForm.controls.eventName.invalid && demoUserForm.controls.eventName.touched}" />
				<div class="invalid-feedback" *ngIf="demoUserForm.controls.eventName.invalid">
					{{demoUserForm.controls.eventName["errorMessage"]}}</div>
			</div>

			<div class="form-group mb-0">
				<label class="mb-0" for="requestor">{{ labels.DEMO_USER_FORM_COMPONENT_REQUESTOR }}&emsp;*</label>
				<input type="text" autocomplete="nope" class="form-control" id="requestor"
					placeholder="{{ labels.DEMO_USER_FORM_COMPONENT_REQUESTOR }}" formControlName="requestor"
					[ngClass]="{'is-valid': demoUserForm.controls.requestor.valid && demoUserForm.controls.requestor.touched, 'is-invalid': demoUserForm.controls.requestor.invalid && demoUserForm.controls.requestor.touched}" />
				<div class="invalid-feedback" *ngIf="demoUserForm.controls.requestor.invalid">
					{{demoUserForm.controls.requestor["errorMessage"]}}</div>
			</div>

			<div class="form-group mb-0" #eventDateTarget>
				<label>{{ labels.DEMO_USER_FORM_COMPONENT_EVENT_DATE }}&emsp;*</label>
				<div class="input-group wrapper-calendar"
					[ngClass]="{'is-valid': demoUserForm.controls.startDate.valid && demoUserForm.controls.endDate.valid, 'is-invalid': (demoUserForm.controls.startDate.invalid || demoUserForm.controls.endDate.invalid) && demoUserForm.controls.eventDateFormat.touched}">
					<i class="material-icons input-group-append icon-calendar" (click)="eventDatePicker.toggle()">today</i>
					<input class="form-control" type="text" placeholder="{{ labels.DEMO_USER_FORM_COMPONENT_EVENT_DATE }}"
						name="eventDateRange" [value]="demoUserForm.value.eventDateFormat" readonly style="color: #fff"
						formControlName="eventDateFormat"
						[ngClass]="{'is-valid': demoUserForm.controls.startDate.valid && demoUserForm.controls.endDate.valid, 'is-invalid': (demoUserForm.controls.startDate.invalid || demoUserForm.controls.endDate.invalid) && demoUserForm.controls.eventDateFormat.touched}">
					<i class="material-icons input-group-append align-self-center md-18" (click)="clearEventDate()">clear</i>
				</div>
				<div class="invalid-feedback"
					*ngIf="demoUserForm.controls.startDate.invalid || demoUserForm.controls.endDate.invalid">
					{{demoUserForm.controls.startDate["errorMessage"] || demoUserForm.controls.endDate["errorMessage"]}}</div>
			</div>
			<daterangepicker #eventDatePicker [range]="demoUserForm.value.eventDateRange"
				(rangeChanged)="updateEventDateRange($event)" [positionTarget]="eventDateTarget" placement="bottom-left">
			</daterangepicker>

			<div class="form-group mb-0">
				<label class="mb-0" for="country">{{ labels.DEMO_USER_FORM_COMPONENT_COUNTRY }}&emsp;*</label>
				<select id="country" autocomplete="nope" class="form-control custom-select" formControlName="country"
					[compareWith]="compareCountry"
					[ngClass]="{'is-valid': demoUserForm.controls.country.valid && demoUserForm.controls.country.touched, 'is-invalid': demoUserForm.controls.country.invalid && demoUserForm.controls.country.touched}">
					<option [ngValue]="null">-</option>
					<option *ngFor="let country of countryOptions" [ngValue]="country">{{ country?.name }}</option>
				</select>
				<div class="invalid-feedback" *ngIf="demoUserForm.controls.country.invalid">{{
					demoUserForm.controls.country["errorMessage"] }}</div>
			</div>

			<div class="form-group mb-0">
				<label class="mb-0 pb-3" for="country">{{ labels.DEMO_USER_FORM_COMPONENT_PRODUCTS }}&emsp;*</label>
				<div class="custom-control custom-checkbox custom-control-inline col-6 mr-0"
					*ngFor="let prod of productOptions">
					<input type="checkbox" class="custom-control-input" [id]="prod.code" name="prod"
						[checked]="demoUserForm.value.products?.includes(prod)" (change)="updateSelectedProducts(prod)" />
					<label class="custom-control-label" [for]="prod.code">{{ prod.description }}</label>
				</div>
				<div class="invalid-feedback d-block"
					*ngIf="demoUserForm.controls.products.invalid && demoUserForm.controls.products.touched">
					{{ demoUserForm.controls.products["errorMessage"] }}
				</div>
			</div>

			<div class="form-group mb-0">
				<label class="mb-0" for="password">{{ labels.DEMO_USER_FORM_COMPONENT_PASSWORD }}&emsp;*</label>
				<input type="text" autocomplete="nope" class="form-control" id="password"
					placeholder="{{ labels.DEMO_USER_FORM_COMPONENT_PASSWORD }}" formControlName="password"
					[ngClass]="{'is-valid': demoUserForm.controls.password.valid && demoUserForm.controls.password.touched, 'is-invalid': demoUserForm.controls.password.invalid && demoUserForm.controls.password.touched}" />
				<div class="invalid-feedback" *ngIf="demoUserForm.controls.password.invalid">
					{{demoUserForm.controls.password["errorMessage"]}}</div>
			</div>

			<button type="button" class="btn btn-primary" [disabled]="demoUserForm.invalid || isSaving"
				(click)="submitForm()">
				{{ labels.DEMO_USER_EDIT_COMPONENT_CONFIRM }}
			</button>

		</div>

	</div>

</form>