import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html'
})
export class ErrorsComponent extends BaseComponent implements OnInit {

  constructor(private langSrv: LanguageService) {
    super(langSrv);
  }

  ngOnInit() {
  }

}
