<div class="nav-top">
  <div class="nav-header">

    <div class="nav-left-side" ngbDropdown container="body" placement="bottom-left">
      <span class="material-icons" ngbDropdownToggle>account_circle</span>
      <span class="welcome-text">{{ labels.NAVBAR_COMPONENT_WELCOME }} {{ userName }}</span>

      <div class="dropdown-menu" ngbDropdownMenu>
        <a href="" onclick="return false;" class="dropdown-item" (click)="openWipeModal()">
          {{ labels.NAVBAR_COMPONENT_WIPE_BTN }}
        </a>
        <div class="simple-text" *ngIf="isVersionVisible">{{buildVersion}}</div>
        <a href="" onclick="return false;" class="dropdown-item" (click)="logout()">
          {{ labels.NAVBAR_COMPONENT_LOGOUT }}
        </a>
      </div>
    </div>

    <div class="nav-center-side">
      <ul class="nav nav-tabs nav-tabs-list" role="tablist">
        <li>
          <a class="tab" id="tab-1-nav" [routerLink]="'/user/list'" routerLinkActive="active" role="tab">
            {{ labels.NAVBAR_COMPONENT_USERS_LIST }}
          </a>
        </li>
        <li>
          <a class="tab" id="tab-2-nav" [routerLink]="'/approvers'" routerLinkActive="active" role="tab">
            {{ labels.NAVBAR_COMPONENT_APPROVERS }}
          </a>
        </li>
        <li>
          <a class="tab" id="tab-3-nav" [routerLink]="'/demo-users'" routerLinkActive="active" role="tab">
            {{ labels.NAVBAR_COMPONENT_DEMO_USERS }}
          </a>
        </li>
        <li>
          <a class="tab" id="tab-4-nav" [routerLink]="'/products'" routerLinkActive="active" role="tab">
            {{ labels.NAVBAR_COMPONENT_DEMO_PRODUCTS }}
          </a>
        </li>
      </ul>
    </div>

    <div class="nav-right-side position-static">
      <span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>
    </div>

  </div>
  <div class="bg-tab-gradient"></div>
</div>
