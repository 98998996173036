import { Component, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DemoUsersRequest, LanguageService } from '../../core';
import { DemoUserService } from '../../services';
import { BaseComponent } from '../../shared';



/**
 * Component of the demo users event list. 
 */
@Component({
	selector: 'demo-event-list',
	templateUrl: './demo-event-list.component.html',
	styles: [
	]
})
export class DemoEventListComponent extends BaseComponent implements OnInit, OnDestroy {

	requestList: DemoUsersRequest[] = [];
	isLoading: boolean;
	isDownloading: boolean;

	readonly ghostList = new Array(2).fill(1);
	private _requestListSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private demoSrv: DemoUserService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this._requestListSub = this.demoSrv.isRequestListChanged().subscribe(() => this.updateRequestList());
		this.updateRequestList();
	}

	private updateRequestList(): void {
		this.requestList = [];
		this.isLoading = true;
		this.demoSrv.getDemoUserRequestList().pipe(
			finalize(() => this.isLoading = false)
		).subscribe(list => this.requestList = [...list]);
	}

	/**
	 * Download pdf with Demo user credentials 
	 * @param id Demo user request guid
	 */
	download(id: string): void {
		if (!this.isDownloading && !!id) {
			this.isDownloading = true;
			this.demoSrv.getDemoUserCredentialPdf(id).pipe(
				finalize(() => this.isDownloading = false)
			).subscribe(response => {
				var blob = new Blob([response], { type: 'application/pdf' });
				saveAs(blob, 'DemoUserCredentials.pdf');
			});
		}
	}

	ngOnDestroy() {
		if (this._requestListSub) {
			this._requestListSub.unsubscribe();
		}
	}

}
