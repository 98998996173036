import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ProductService } from '../services';
import { ProductEditComponent } from './product-edit';



@NgModule({
	declarations: [
		ProductEditComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		RxReactiveFormsModule
	],
	providers: [
		ProductService
	]
})
export class ProductsModule { }
