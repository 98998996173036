import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Country, CountryProduct, DictionaryEnum, Product, ProductTypeEnum, UserRepository, countryMapper } from '../core';
import { BaseService } from './base.service';



/**
* This service handles products.
*/
@Injectable()
export class ProductService extends BaseService {

	private _countries: Country[] = [];
	private _products: Product[] = [];

	constructor(private usrRepo: UserRepository) {
		super();
	}

	/**
	* Initalize dictionaries from repository.
	*/
	initDictionaries(): Observable<[Country[], Product[]]> {
		if (this._countries.length > 0 && this._products.length > 0) {
			return of([this._countries, this._products]);
		}
		return combineLatest([this.getCountryList(), this.getProductList()]).pipe(
			filter(([countries, prods]) => !!countries?.length && !!prods?.length),
			tap(([countries, prods]) => {
				this._countries = countries;
				this._products = prods;
			}),
			map(() => [this._countries, this._products])
		);
	}

	/**
	 * Get list of countries.
	 */
	getCountryList(): Observable<Country[]> {
		return this.usrRepo.getDictionaries(DictionaryEnum.COUNTRY).pipe(
			map(res => this.handleApiResponse(res)),
			filter(dicList => !!dicList && dicList.length === 1),
			map(dicList => dicList[0]),
			map(dic => dic.values),
			map(list => list.map(item => countryMapper(item))),
			map(list => list.sort((a, b) => new Intl.Collator().compare(a.name, b.name)))
		);
	}

	/**
	 * Get product list.
	 */
	getProductList(): Observable<Product[]> {
		return this.usrRepo.getProductList().pipe(
			map(res => this.handleApiResponse(res)),
			map(prods => orderedProducts(prods))
		);
	}

	/**
	 * Get country product list.
	 */
	getCountryProductList(countryCode?: string): Observable<CountryProduct[]> {
		return this.usrRepo.getCountryProducts(countryCode).pipe(
			map(res => this.handleApiResponse(res)),
			map(this.orderCountryProducts)
		);
	}

	private orderCountryProducts(list: CountryProduct[]): CountryProduct[] {
		const clt = new Intl.Collator();
		return list.sort((a, b) => clt.compare(a.country.value, b.country.value) || clt.compare(a.product.description, b.product.description));
	}

	/**
	 * Edit country products.
	 */
	editCountryProducts(countryCode: string, productCodes: string[]): Observable<void> {
		return this.usrRepo.editCountryProducts(countryCode, productCodes).pipe(
			map(res => this.handleApiResponse(res))
		);
	}
}


/**
 * Check if product is disabled according to current product list.
 */
export function isProductDisabled(prod: Product, currentProds: Product[]): boolean {
	const hasPlatformDemo = currentProds?.map(p => p.type).includes(ProductTypeEnum.Platform);
	const hasProds = currentProds?.some(p => !p.demo);
	const hasTlhexprod = currentProds?.some(p => !p.demo && p.type === ProductTypeEnum.TLHex);
	return (hasPlatformDemo && !prod.demo) ||
		(hasProds && prod.type === ProductTypeEnum.Platform) ||
		(!hasTlhexprod && (prod.type === ProductTypeEnum.MyHexPlan || prod.type === ProductTypeEnum.IWrench));
}

/**
 * Get filteres products.
 */
export function filteredProducts(prod: Product, currentProds: Product[], productProdOptions?: Product[]): Product[] {
	let otherProds: Product[] = [];
	if (currentProds.some(p => p.code === prod.code)) {
		otherProds = currentProds.filter(p => p.code !== prod.code && filterTlhexProducts(prod, p));
	} else {
		otherProds = currentProds.filter(p => p.type !== prod.type && filterTlhexProducts(prod, p));
		// force myhexplan product for iwrench product
		if (prod.type === ProductTypeEnum.IWrench && !otherProds.some(p => p.type === ProductTypeEnum.MyHexPlan) && productProdOptions?.length) {
			const myhexplanProd = productProdOptions.find(p => p.type === ProductTypeEnum.MyHexPlan);
			otherProds.push(myhexplanProd);
		}
	}
	return otherProds;
}

function filterTlhexProducts(currentProd: Product, otherProd: Product): boolean {
	if (currentProd.type === ProductTypeEnum.TLHex) return otherProd.type !== ProductTypeEnum.MyHexPlan && otherProd.type !== ProductTypeEnum.IWrench;
	if (currentProd.type === ProductTypeEnum.MyHexPlan) return otherProd.type !== ProductTypeEnum.IWrench;
	return true;
}

export function orderedProducts(products: Product[]): Product[] {
	// order by type, then version, then demo
	const clt = new Intl.Collator();
	products.sort((a, b) => clt.compare(a.type, b.type) || clt.compare(a.version, b.version) || clt.compare(String(!a.demo), String(!b.demo)));

	// move platform demo at beginning
	products.unshift(products.splice(products.findIndex(item => item.type === ProductTypeEnum.Platform), 1)[0]);
	// move myhexplan at the end
	products.push(products.splice(products.findIndex(item => item.type === ProductTypeEnum.MyHexPlan), 1)[0]);
	// move iwrench at the end
	products.push(products.splice(products.findIndex(item => item.type === ProductTypeEnum.IWrench), 1)[0]);

	return products;
}
