import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRayRepository } from './Iray.repository';
import { environment } from 'src/environments/environment';

@Injectable()
export class RayRepository implements IRayRepository {
  constructor(private httpClient: HttpClient) { }

  wipeAllData(): Observable<void> {
    return this.httpClient.post<void>(environment.domainRayAPI + '/api/model/wipealldata', null);
  }
}
