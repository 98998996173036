import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { User, UserRepository } from '../core';
import { BaseService } from './base.service';

/**
* This service handles logged user profile.
*/
@Injectable()
export class UserService extends BaseService {

	private _currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

	constructor(private usrRepo: UserRepository) {
		super();
	}

	/**
	* Get current user profile.
	*/
	getCurrentUser(): Observable<User> {
		if (this._currentUser$.getValue()) {
			return this._currentUser$.asObservable();
		}
		return this.getUserProfile().pipe(filter(user => !!user), tap(user => this._currentUser$.next(user)));
	}

	private getUserProfile(): Observable<User> {
		return this.usrRepo.getUserProfile().pipe(map(res => this.handleApiResponse(res)));
	}

}
