export interface ApiResponse<T> {
	statusCode: ApiResponseStatusCode;
	errorMessage: string;
	result?: T;
}

export enum ApiResponseStatusCode {
	Success = "Success",
	ItemNotFound = "ItemNotFound",
	UserNameNotUnique = "UserNameNotUnique",
	GenericError = "GenericError"
}
