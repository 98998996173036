import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Country, LanguageService, PracticeType, Product, RoleInformationType, RoleTypeEnum, SURGEON, ToastService, USState, US_CODE, UserRoles, UserStatusType, compareCountry, convertToLocalDate } from '../../core';
import { UserEditForm } from '../../models';
import { UserEditService, filteredProducts, isProductDisabled } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component with form to edit user data.
*/
@Component({
	selector: 'edit-form',
	templateUrl: './edit-form.component.html'
})
export class EditFormComponent extends BaseComponent implements OnInit, OnDestroy {

	editForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(UserEditForm);

	userId: string;
	userStatus: UserStatusType;
	countryOptions: Country[] = [];
	usStateOptions: USState[] = [];
	practiceTypeOptions: PracticeType[] = [];
	roleInfoOptions: RoleInformationType[] = [];
	productDemoOptions: Product[] = [];
	productProdOptions: Product[] = [];

	isFormVisible: boolean
	isPracticeVisible: boolean;
	isDistributorVisible: boolean;
	isHospitalVisible: boolean;
	isUSStateVisible: boolean;
	isSaving: boolean;

	compareCountry = compareCountry;

	private _editUserSub: Subscription;

	readonly usrRoleOptions_1: string[] = [UserRoles.SURGEON, UserRoles.SALES, UserRoles.ORTHOFIX_USER];
	readonly usrRoleOptions_2: string[] = [UserRoles.ADMIN, UserRoles.CUSTOMER_CARE];
	readonly usrRoleOptions_3: string[] = [UserRoles.MARKETING, UserRoles.DEMO_USER];
	readonly maxDateOfBirth: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
	readonly userStatusEnum = UserStatusType;

	constructor(
		private langSrv: LanguageService,
		private usrEditSrv: UserEditService,
		private route: ActivatedRoute,
		private formBuilder: RxFormBuilder,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.userId = this.route.snapshot.paramMap.get('userId');
		this.initForm();
		this.usrEditSrv.initDictionaries().subscribe(res => {
			this.countryOptions = this.usrEditSrv.getCountries();
			this.usStateOptions = this.usrEditSrv.getUSStates();
			this.practiceTypeOptions = this.usrEditSrv.getPracticeTypes();
			this.roleInfoOptions = this.usrEditSrv.getRoleInfoTypes();
			this.productDemoOptions = this.usrEditSrv.getProducts().filter(p => p.demo);
			this.productProdOptions = this.usrEditSrv.getProducts().filter(p => !p.demo);
			this.initEditForm();
		});
		this._editUserSub = this.usrEditSrv.getEditUser().subscribe(user => this.userStatus = <UserStatusType>user?.status);
	}

	/**
	 * Initialize form configurations.
	 */
	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched,
			"baseConfig": {
				"dateFormat": "ymd",
				"seperator": "-"
			}
		});
	}

	/**
	 * Initialize form values from route user id.
	 */
	private initEditForm(): void {
		this.editForm.reset();
		this.usrEditSrv.getEditForm(this.userId).subscribe(form => {
			this.editForm = <RxFormGroup>this.formBuilder.formGroup(UserEditForm, form);
			this.updateStateProvince();
			this.updateRoleType();
			this.isFormVisible = !!form;
		});
	}

	/**
	* Reset UserNameNotUnique flag after validation
	*/
	resetUserNameNotUnique() {
		if (this.editForm.value.userNameNotUnique) {
			this.editForm.controls.userNameNotUnique.setValue(false);
			this.editForm.controls.userName.setValue(this.editForm.value.userName);
		}
	}

	/**
	* Update date of birth string format
	* @param {Date} date Date selected from date picker
	*/
	updateDateOfBirth(date: Date): void {
		this.editForm.controls.dateOfBirth.setValue(date);
		this.editForm.controls.dateOfBirthFormat.setValue(convertToLocalDate(date));
		this.editForm.controls.dateOfBirth.markAsTouched();
	}

	/**
	* Reset date of birth
	*/
	clearDateOfBirth(): void {
		this.editForm.controls.dateOfBirth.setValue(null);
		this.editForm.controls.dateOfBirth.markAsTouched();
		this.editForm.controls.dateOfBirthFormat.setValue(null);
	}

	/**
	 * Check if user role checkbox is disabled.
	 */
	isUserRoleDisabled(role: string): boolean {
		return (<string[]>this.editForm.value.roles)?.some(role => this.usrRoleOptions_1.includes(role)) && !(<string[]>this.editForm.value.roles)?.includes(role);
	}

	/**
	 * Update current list of selected roles.
	 */
	updateSelectedRoles(role: string): void {
		const index = this.editForm.value.roles.indexOf(role);
		let newValue = [...this.editForm.value.roles];
		if (index === -1) {
			newValue = newValue.concat(role);
		} else {
			newValue.splice(index, 1);
		}
		this.editForm.controls.roles.setValue(newValue);
		this.updateRoleType();
	}

	private get _currentProds(): Product[] {
		return this.editForm.value.products;
	}

	/**
	 * Check if product is selected.
	 */
	isProductSelected(prodCode: string): boolean {
		return this._currentProds.some(p => p.code === prodCode);
	}

	/**
	 * Check if product is disabled.
	 */
	isProductDisabled(prod: Product): boolean {
		return isProductDisabled(prod, this._currentProds);
	}

	/**
	 * Update current list of selected products.
	 */
	updateSelectedProducts(prod: Product): void {
		const otherProds = filteredProducts(prod, this._currentProds, this.productProdOptions);
		if (this.isProductSelected(prod.code)) {
			this.editForm.controls.products.setValue([...otherProds]);
		} else {
			this.editForm.controls.products.setValue([...otherProds, prod]);
		}
	}

	/**
	 * Update role type field.
	 */
	private updateRoleType(): void {
		if ((<string[]>this.editForm.value.roles)?.includes(SURGEON)) {
			this.editForm.controls.roleType.setValue(RoleTypeEnum.HCP);
		} else {
			this.editForm.controls.roleType.setValue(RoleTypeEnum.OTHERS);
		}
		this.updateHospitalOrCompany();
		this.updatePractice();
	}

	/**
	 * Update hospital/company fields.
	 */
	private updateHospitalOrCompany(): void {
		this.isHospitalVisible = this.isHCP(this.editForm.value.roleType);
		this.isDistributorVisible = this.isHCP(this.editForm.value.roleType);
	}

	/**
	 * Update state province fields.
	 */
	updateStateProvince(): void {
		this.isUSStateVisible = this.isUnitedStates(this.editForm.value.country);
		this.updatePractice();
	}

	/**
	 * Update practice fields.
	 */
	private updatePractice(): void {
		this.isPracticeVisible = this.isUnitedStates(this.editForm.value.country) && this.isHCP(this.editForm.value.roleType);
	}

	/**
	 * Reset form data with initial values.
	 */
	resetForm(): void {
		if (!this.isSaving) {
			this.editForm.resetForm();
			this.updateStateProvince();
			this.updateRoleType();
		}
	}

	/**
	 * Change current user status with new input  status.
	 */
	changeStatus(status: UserStatusType): void {
		this.isSaving = true;
		this.usrEditSrv.changeUserStatus(this.userId, status).pipe(
			tap(res => { if (!res) throw new Error('Status not changed!') }),
			finalize(() => this.isSaving = false)
		).subscribe(res => {
			this.initEditForm();
			this.toastSrv.showSuccess(this.labels['USER_FORM_COMPONENT_UPDATE_SUCCESS'])
		}, err => this.toastSrv.showError(this.labels['USER_FORM_COMPONENT_UPDATE_ERROR'])
		);
	}

	/**
	 * Update user edit data.
	 */
	update(): void {
		if (this.editForm.valid && !this.isSaving) {
			this.isSaving = true;
			this.usrEditSrv.submitForm(<UserEditForm>this.editForm.modelInstance).pipe(
				finalize(() => this.isSaving = false)
			).subscribe(res => {
				this.initEditForm();
				this.toastSrv.showSuccess(this.labels['USER_FORM_COMPONENT_UPDATE_SUCCESS']);
			}, (err: Error) => {
				this.toastSrv.showError(this.labels['USER_FORM_COMPONENT_UPDATE_ERROR']);
				this.handleUserNameNotUnique(err);
			});
		}
	}

	private handleUserNameNotUnique(err: Error): void {
		if (err.message === "UserNameNotUnique") {
			this.editForm.controls.userNameNotUnique.setValue(true);
			this.editForm.controls.userName.setValue(this.editForm.value.userName);
		}
	}

	/**
	 * Check if input role is HCP.
	 */
	private isHCP(type: RoleTypeEnum): boolean {
		return type && type === RoleTypeEnum.HCP;
	}

	/**
	 * Check if input country is United States.
	 */
	private isUnitedStates(country: Country): boolean {
		return country && country?.code === US_CODE;
	}

	/**
	 * Check if active button is enabled.
	 */
	get isActiveEnabled(): boolean {
		return [UserStatusType.Unprocessed, UserStatusType.Suspended, UserStatusType.Denied].includes(this.userStatus) && !this.isSaving;
	}

	/**
	 * Check if deny button is enabled.
	 */
	get isDenyEnabled(): boolean {
		return this.userStatus === UserStatusType.Unprocessed && !this.isSaving;
	}

	/**
	 * Check if suspend button is enabled.
	 */
	get isSuspendEnabled(): boolean {
		return this.userStatus === UserStatusType.Active && !this.isSaving;
	}

	/**
	 * Check if save button is enabled.
	 */
	get isSaveEnabled(): boolean {
		return this.editForm.valid && this.userStatus === UserStatusType.Active && !this.isSaving;
	}

	ngOnDestroy() {
		if (this._editUserSub) {
			this._editUserSub.unsubscribe();
		}
	}

}
