import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@ortho-next/auth-core';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { LanguageService, MonitoringService } from './core';
import { BaseComponent } from './shared';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {

	constructor(
		private authService: AuthService,
		private langSrv: LanguageService,
		private monitoringService: MonitoringService,
		private titleService: Title
	) {
		super(langSrv);

		authService.onLogout$.subscribe(() => monitoringService.clearAuthenticatedUserId());

		from(this.authService.runInitialLoginSequence()).pipe(
			switchMap(() => this.authService.isDoneLoading$),
			filter(() => this.authService.hasValidToken()),
			map(() => this.authService.userName),
			filter(userName => !!userName),
		).subscribe(userName => monitoringService.setAuthenticatedUserId(userName));

	}

	ngOnInit() {
		this.titleService.setTitle(this.labels.APP_MAIN_TITLE);
	}

}
