import { Component } from '@angular/core';
import { LanguageService } from '../../core/language.service';

@Component({
  selector: 'base-component',
  templateUrl: './base.component.html'
})
export class BaseComponent {

  private _labels: any = {};

  constructor(private languageSrv: LanguageService) {
    this._labels = languageSrv.getLabels();
  }

  get labels() {
    return this._labels;
  }

}
