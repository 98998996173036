<div class="row">
	<div class="col d-flex align-items-center mb-4">

		<!-- Breadcrumbs -->
		<div class="breadcrumbs d-flex mb-0" style="width: 33%;">
			<a href="#" onclick="return false;" (click)="back()"><i class="material-icons">arrow_back</i>{{
				labels.USER_EDIT_COMPONENT_BACK }}</a>
		</div>

		<!-- Page title -->
		<h1 class="d-flex justify-content-center" style="width: 33%;">{{ labels.USER_EDIT_COMPONENT_TITLE }}</h1>

		<div class="d-flex justify-content-end align-items-center" style="width: 33%;" *ngIf="user">
			<span>{{ user?.userName }}</span>
			<div class="user-holder ml-4" [ngClass]="user?.status | lowercase" [title]="user?.status">
				<span>{{ user?.status | uppercase | slice:0:2 }}</span>
			</div>
		</div>

	</div>
</div>

<div class="row row-contents">

	<div class="col-12">

		<ngb-accordion #acc="ngbAccordion" activeIds="data-panel" [destroyOnHide]="false">


			<ngb-panel id="data-panel" cardClass="panel panel-accordion">

				<ng-template ngbPanelHeader let-opened="opened">
					<div class="custom-header" (click)="acc.toggle('data-panel')">
						<h2 class="mb-0">{{ labels.USER_EDIT_COMPONENT_FORM_HEADER }}</h2>
						<span class="material-icons" [class.opened]="opened">expand_more</span>
					</div>
				</ng-template>

				<ng-template ngbPanelContent>
					<edit-form></edit-form>
				</ng-template>

			</ngb-panel>

			<ngb-panel id="pwd-panel" cardClass="panel panel-accordion" [disabled]="isPwdPanelDisabled">

				<ng-template ngbPanelHeader let-opened="opened">
					<div class="custom-header" [class.disabled]="isPwdPanelDisabled" (click)="acc.toggle('pwd-panel')">
						<h2 class="mb-0">{{ labels.USER_EDIT_COMPONENT_PWD_HEADER }}</h2>
						<span class="material-icons" [class.opened]="opened">expand_more</span>
					</div>
				</ng-template>

				<ng-template ngbPanelContent>
					<password-edit></password-edit>
				</ng-template>

			</ngb-panel>

			<ngb-panel id="note-panel" cardClass="panel panel-accordion">

				<ng-template ngbPanelHeader let-opened="opened">
					<div class="custom-header" (click)="acc.toggle('note-panel')">
						<h2 class="mb-0">{{ labels.USER_EDIT_COMPONENT_NOTE_HEADER }}</h2>
						<span class="material-icons" [class.opened]="opened">expand_more</span>
					</div>
				</ng-template>

				<ng-template ngbPanelContent>
					<note-edit></note-edit>
				</ng-template>

			</ngb-panel>

		</ngb-accordion>

	</div>

</div>