import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorMessageBindingStrategy, pattern, ReactiveFormConfig, required, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LanguageService, ToastService, User } from '../../core';
import { UserEditService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component with form to edit user password.
*/
@Component({
	selector: 'password-edit',
	templateUrl: './password-edit.component.html'
})
export class PasswordEditComponent extends BaseComponent implements OnInit, OnDestroy {

	user: User;
	pwdForm: RxFormGroup;
	isSaving: boolean;

	private _editUserSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private formBuilder: RxFormBuilder,
		private usrEditSrv: UserEditService,
		private toastSrv: ToastService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this._editUserSub = this.usrEditSrv.getEditUser().subscribe(usr => this.user = usr);
		this.initForm();
	}

	private initForm(): void {
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched
		});
		this.pwdForm = <RxFormGroup>this.formBuilder.formGroup(PasswordForm, { password: '' } as PasswordForm);
	}

	/**
	 * Submit password form.
	 */
	change(): void {
		if (this.pwdForm.valid && this.user) {
			this.isSaving = true;
			this.usrEditSrv.changeUserPassword(this.user.userName, this.pwdForm.value.password).pipe(
				finalize(() => {
					this.isSaving = false;
					this.usrEditSrv.loadEditUser(this.user.id).subscribe();
				})
			).subscribe({
				next: () => this.toastSrv.showSuccess(this.labels['PASSWORD_EDIT_COMPONENT_CHANGE_SUCCESS']),
				error: () => this.toastSrv.showError(this.labels['PASSWORD_EDIT_COMPONENT_CHANGE_ERROR'])
			});
		}
	}

	ngOnDestroy() {
		this._editUserSub?.unsubscribe();
	}

}


const PWD_PATTERN = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,16}$/;
/**
 * Password form model.
 */
class PasswordForm {
	@required({ messageKey: 'VALIDATION_MESSAGE_REQUIRED_PASSWORD' })
	@pattern({ expression: { 'validPwd': PWD_PATTERN }, messageKey: 'VALIDATION_MESSAGE_PATTERN_PASSWORD' })
	password: string;
}
