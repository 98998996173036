import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Country, DemoUser, DemoUsersRequest, DemoUsersRequestInsert, DictionaryEnum, Product, UserRepository, convertToISOString, countryMapper } from '../core';
import { DemoUserForm } from '../models';
import { BaseService } from './base.service';
import { orderedProducts } from './product.service';



/**
* This service handles Demo users.
*/
@Injectable()
export class DemoUserService extends BaseService {

	private _changeRequestList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	private _countries: Country[] = [];
	private _products: Product[] = [];

	constructor(
		private usrRepo: UserRepository
	) {
		super();
	}

	/**
	* Initalize dictionaries from repository.
	*/
	initDictionaries(): Observable<[Country[], Product[]]> {
		if (this._countries.length > 0 && this._products.length > 0) {
			return of([this._countries, this._products]);
		}
		return combineLatest([this.getCountryList(), this.getDemoProductList()]).pipe(
			filter(([countries, prods]) => !!countries?.length && !!prods?.length),
			tap(([countries, prods]) => {
				this._countries = countries;
				this._products = prods;
			}),
			map(() => [this._countries, this._products])
		);
	}

	/**
	 * Get list of countries.
	 */
	private getCountryList(): Observable<Country[]> {
		return this.usrRepo.getDictionaries(DictionaryEnum.COUNTRY).pipe(
			map(res => this.handleApiResponse(res)),
			filter(dicList => !!dicList && dicList.length === 1),
			map(dicList => dicList[0]),
			map(dic => dic?.values),
			map(list => list?.map(item => countryMapper(item))),
			map(list => list?.sort((a, b) => new Intl.Collator().compare(a.name, b.name)))
		);
	}

	/**
	 * Get Demo product list.
	 */
	getDemoProductList(): Observable<Product[]> {
		return this.usrRepo.getProductList().pipe(
			map(res => this.handleApiResponse(res)),
			map(prods => orderedProducts(prods)),
			map(prods => prods.filter(p => p.demo))
		);
	}

	/**
	 * Get list of Demo users.
	 * @returns List of Demo user
	 */
	getDemoUserList(): Observable<DemoUser[]> {
		return this.usrRepo.getDemoUserList().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	* Submit Demo user form and create event request.
	*/
	submitForm(form: DemoUserForm): Observable<void> {
		const request: DemoUsersRequestInsert = this.demoUserRequestMapper(form);
		return this.usrRepo.createDemoEvent(request).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	private demoUserRequestMapper(form: DemoUserForm): DemoUsersRequestInsert {
		if (!form) return null;
		return {
			eventName: form.eventName,
			requestor: form.requestor,
			startDate: new Date(convertToISOString(form.startDate)),
			endDate: new Date(convertToISOString(form.endDate)),
			countryCode: form.country?.code,
			productCodes: form.products?.map(p => p.code),
			password: form.password,
			demoUsers: form.users
		}
	}

	/**
	 * Get list of Demo users requests.
	 * @returns List of Demo user requests
	 */
	getDemoUserRequestList(): Observable<DemoUsersRequest[]> {
		return this.usrRepo.getDemoUserRequestList().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Trigger update action of Demo user request list.
	 */
	updateRequestList(): void {
		this._changeRequestList.next(true);
	}

	/**
	 * Check if Demo user request list is changed.
	 */
	isRequestListChanged(): Observable<boolean> {
		return this._changeRequestList.asObservable().pipe(filter(val => !!val));
	}

	/**
	 * Get pdf with Demo user credentials.
	 * @param requestGuid Demo user request guid
	 */
	getDemoUserCredentialPdf(requestGuid: string): Observable<Blob> {
		return this.usrRepo.getDemoUserCredentialsPdf(requestGuid);
	}

}
