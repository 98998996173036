import { choice, date, maxLength, minDate, notEmpty, pattern, prop, required, trim } from "@rxweb/reactive-form-validators";
import { Country, Product } from "../core";
import { DateRange } from "../shared";

const specialChars: RegExp = /^[^<>%#&?,]+$/;
const PWD_PATTERN: RegExp = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,16}$/;


/**
 * Demo user event creation form model.
 */
export class DemoUserForm {

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_EVENT_NAME" })
	@notEmpty({ messageKey: "VALIDATION_MESSAGE_REQUIRED_EVENT_NAME" })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_EVENT_NAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_EVENT_NAME" })
	@trim()
	eventName: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_REQUESTOR" })
	@notEmpty({ messageKey: "VALIDATION_MESSAGE_REQUIRED_REQUESTOR" })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_REQUESTOR" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_REQUESTOR" })
	@trim()
	requestor: string;

	@date()
	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_START_DATE" })
	@minDate({ value: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_START_DATE" })
	startDate: Date;

	@date()
	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_END_DATE" })
	@minDate({ fieldName: 'startDate', operator: '>', messageKey: "VALIDATION_MESSAGE_INVALID_END_DATE" })
	endDate: Date;

	@prop()
	eventDateFormat: string;

	@prop()
	eventDateRange: DateRange;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COUNTRY" })
	country: Country;

	@choice({ minLength: 1, messageKey: "VALIDATION_MESSAGE_REQUIRED_PRODUCTS" })
	products: Product[] = [];

	@required({ messageKey: 'VALIDATION_MESSAGE_REQUIRED_PASSWORD' })
	@pattern({ expression: { 'validPwd': PWD_PATTERN }, messageKey: 'VALIDATION_MESSAGE_PATTERN_PASSWORD' })
	@trim()
	password: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_USER_LIST" })
	@choice({ minLength: 1, messageKey: "VALIDATION_MESSAGE_LENGTH_USER_LIST" })
	users: string[];
}