import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AdminUserItem, AdminUserSearchOption, FilterOption, LanguageService, SortOption } from '../../core';
import { UserSearchService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of user list page.
*/
@Component({
	selector: 'user-list',
	templateUrl: './user-list.component.html'
})
export class UserListComponent extends BaseComponent implements OnInit {

	initialFilter: FilterOption = {};
	initialSortOrder: SortOption = {};
	userList: AdminUserItem[];
	loading: boolean;

	private _searchOption: AdminUserSearchOption = {};

	constructor(
		private langSrv: LanguageService,
		private usrSearchSrv: UserSearchService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this._searchOption = { ...this.usrSearchSrv.searchOption };
		this.initialFilter = Object.assign({}, this._searchOption?.filter);
		this.initialSortOrder = Object.assign({}, this._searchOption?.sortOrder);
		this.updateUserList(this._searchOption);
	}

	/**
	* Update search filter option
	*/
	updateFilter(filter: FilterOption): void {
		this._searchOption = Object.assign({}, { filter: filter, sortOrder: this._searchOption.sortOrder });
		this.updateUserList(this._searchOption);
	}

	/**
	* Update search sort order option
	*/
	updateSortOrder(order: SortOption): void {
		this._searchOption = Object.assign({}, { filter: this._searchOption.filter, sortOrder: order });
		this.updateUserList(this._searchOption);
	}

	/**
	* Update user list by options
	*/
	private updateUserList(option: AdminUserSearchOption): void {
		this.usrSearchSrv.searchOption = { ...this._searchOption };
		this.loading = true;
		this.usrSearchSrv.getUserList(option).pipe(
			finalize(() => this.loading = false)
		).subscribe(list => this.userList = list, err => this.userList = []);
	}

	/**
	 * Export user list.
	 */
	exportList(): void {
		this.usrSearchSrv.exportUserList(this.userList);
	}

}
