import { environment } from 'src/environments/environment';
import { AuthCoreConfig } from '@ortho-next/auth-core';

export const AUTH_CONFIG: AuthCoreConfig = {
  allowedUrls: [environment.domainUserAPI, environment.domainPatientAPI, environment.domainImageAPI, environment.domainRayAPI],
  appSSOutUriApi: environment.appSSOutUriApi,
  applicationScope: environment.b2cApplicationScope,
  clientId: environment.b2cClientId,
  idleTimeToEndSession: environment.idleTimeToEndSession,
  tenantName: environment.b2cTenantName,
  resetPasswordPolicyId: environment.b2cResetPasswordPolicyId,
  signInPolicyId: environment.b2cSignInPolicyId,
  showDebugInformation: !environment.production,
};
