<h1 class="mb-5">{{ labels.DEMO_USER_EDIT_COMPONENT_TITLE }}</h1>

<demo-user-form></demo-user-form>

<div class="row row-contents mt-5">

	<div class="col-12">

		<ngb-accordion #acc="ngbAccordion" activeIds="event-panel" [destroyOnHide]="false">


			<ngb-panel id="event-panel" cardClass="panel panel-accordion">

				<ng-template ngbPanelHeader let-opened="opened">
					<div class="custom-header" (click)="acc.toggle('event-panel')">
						<h2 class="mb-0">{{ labels.DEMO_USER_EDIT_COMPONENT_HEADER }}</h2>
						<span class="material-icons" [class.opened]="opened">expand_more</span>
					</div>
				</ng-template>

				<ng-template ngbPanelContent>
					<demo-event-list></demo-event-list>
				</ng-template>

			</ngb-panel>

		</ngb-accordion>

	</div>

</div>