import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AdminUserItem, AdminUserSearchOption, Country, countryMapper, createWorkBook, DictionaryEnum, FilterOption, PracticeType, Role, RoleInformationType, saveWorkBook, SortFields, SortOption, User, UserRepository, USState, usStateMapper } from '../core';
import { flatUserMapper, UserFlat } from '../models';
import { BaseService } from './base.service';


export const DEFAULT_FILTERS: FilterOption = { unprocessed: true };
export const DEFAULT_SORT_ORDER: SortOption = { name: SortFields.requestDate, desc: true };


/**
* This service handles user search form and list.
*/
@Injectable()
export class UserSearchService extends BaseService {

	private _searchOption: AdminUserSearchOption = { filter: { ...DEFAULT_FILTERS }, sortOrder: { ...DEFAULT_SORT_ORDER } };

	private _countries: Country[] = [];
	private _usStates: USState[] = [];
	private _roles: Role[] = [];
	private _roleInfoTypes: RoleInformationType[] = [];
	private _practiceTypes: PracticeType[] = [];

	constructor(private usrRepo: UserRepository) {
		super();
	}

	/**
	* Load and initialize dictionary list.
	*/
	getDictionaries(): Observable<boolean> {
		if (this._countries.length > 0 && this._usStates.length > 0 && this._roleInfoTypes.length > 0 && this._practiceTypes.length > 0) {
			return of(true);
		}
		return this.usrRepo.getDictionaries().pipe(
			map(res => this.handleApiResponse(res)),
			filter(list => !!list && list.length > 0),
			tap(list => {
				const dics = list.map(x => x.name);
				this._countries = dics.includes(DictionaryEnum.COUNTRY) ? list.find(x => x.name == DictionaryEnum.COUNTRY).values.map(country => countryMapper(country)) : [];
				this._usStates = dics.includes(DictionaryEnum.US_STATE) ? list.find(x => x.name == DictionaryEnum.US_STATE).values.map(state => usStateMapper(state)) : [];
				this._roles = dics.includes(DictionaryEnum.ROLE) ? list.find(x => x.name == DictionaryEnum.ROLE).values : [];
				this._roleInfoTypes = dics.includes(DictionaryEnum.ROLE_INFORMATION_TYPE) ? list.find(x => x.name == DictionaryEnum.ROLE_INFORMATION_TYPE).values : [];
				this._practiceTypes = dics.includes(DictionaryEnum.PRACTICE_TYPE) ? list.find(x => x.name == DictionaryEnum.PRACTICE_TYPE).values : [];
				this._countries.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
				this._usStates.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
			}),
			map(list => true)
		);
	}

	/**
	* Get country dictionary list.
	*/
	getCountries(): Country[] {
		return this._countries;
	}

	/**
	* Get country dictionary list.
	*/
	getUSStates(): USState[] {
		return this._usStates;
	}

	/**
	* Get roles dictionary list.
	*/
	getRoles(): Role[] {
		return this._roles;
	}

	/**
	* Get role info types dictionary list.
	*/
	getRoleInfoTypes(): RoleInformationType[] {
		return this._roleInfoTypes;
	}

	/**
	* Get practice type dictionary list.
	*/
	getPracticeTypes(): PracticeType[] {
		return this._practiceTypes;
	}

	/**
	* Load user list by input options.
	*/
	getUserList(option: AdminUserSearchOption): Observable<AdminUserItem[]> {
		return this.usrRepo.getUserList(option).pipe(map(res => this.handleApiResponse(res)));
	}

	/**
	* Get current search option.
	*/
	get searchOption(): AdminUserSearchOption {
		return this._searchOption;
	}

	/**
	* Set current search option.
	*/
	set searchOption(option: AdminUserSearchOption) {
		this._searchOption = option;
	}

	/**
	 * Export user list in excel file.
	 * @param list List of user to export.
	 */
	exportUserList(list: User[]): void {
		const fileName: string = 'UserList';
		const sheetName: string = 'UserList';

		const headers: { key: keyof UserFlat, header: string }[] = [
			{ key: 'status', header: 'Status' },
			{ key: 'userName', header: 'User Name' },
			{ key: 'firstName', header: 'First Name' },
			{ key: 'lastName', header: 'Last Name' },
			{ key: 'dateOfBirth', header: 'Date Of Birth' },
			{ key: 'roles', header: 'Roles' },
			{ key: 'roleInfo', header: 'Role info' },
			{ key: 'hospitalOrCompany', header: 'Hospital/Company' },
			{ key: 'distributor', header: 'Distributor' },
			{ key: 'country', header: 'Country' },
			{ key: 'stateProvince', header: 'State/Province' },
			{ key: 'address', header: 'Address' },
			{ key: 'city', header: 'City' },
			{ key: 'postalCode', header: 'Postal Code' },
			{ key: 'officePhone', header: 'Office Phone' },
			{ key: 'mobilePhone', header: 'Mobile Phone' },
			{ key: 'practiceType', header: 'Practice Type' },
			{ key: 'npi', header: 'NPI' },
			{ key: 'stateCode1', header: 'Iº Licence State' },
			{ key: 'licenseNumber1', header: 'Iº Licence Number' },
			{ key: 'stateCode2', header: 'IIº Licence State' },
			{ key: 'licenseNumber2', header: 'IIº Licence Number' },
			{ key: 'stateCode3', header: 'IIIº Licence State' },
			{ key: 'licenseNumber3', header: 'IIIº Licence Number' },
			{ key: 'stateCode4', header: 'IVº Licence State' },
			{ key: 'licenseNumber4', header: 'IVº Licence Number' },
			{ key: 'stateCode5', header: 'Vº Licence State' },
			{ key: 'licenseNumber5', header: 'Vº Licence Number' }
		];

		const items: UserFlat[] = list?.map(user => flatUserMapper(user));

		const workBook = createWorkBook(headers, items, sheetName);

		saveWorkBook(workBook, fileName);
	}

}
