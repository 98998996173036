import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DemoUser, LanguageService } from '../../core';
import { BaseComponent } from '../../shared';



/**
 * Component of the demo users table. 
 */
@Component({
	selector: 'demo-user-list',
	templateUrl: './demo-user-list.component.html',
	styles: [
	]
})
export class DemoUserListComponent extends BaseComponent implements OnInit {

	@Input() isLoading: boolean;
	@Input() userList: DemoUser[] = [];
	@Output() onSelect: EventEmitter<string[]> = new EventEmitter<string[]>();

	ghostList = new Array(5).fill(1);
	private _selected: string[] = [];

	constructor(
		private langSrv: LanguageService
	) {
		super(langSrv);
	}

	/**
	 * Update current list of selected users.
	 */
	updateSelected(id: string): void {
		const index = this._selected.indexOf(id);
		if (index === -1) {
			this._selected.push(id);
		} else {
			this._selected.splice(index, 1);
		}
		this.onSelect.emit([...this._selected]);
	}

	ngOnInit(): void {
	}

}
