<div class="panel panel-thead">
	<div class="row no-gutters">

		<div class="col no-link"></div>
		<div class="col no-link">{{ labels.DEMO_USER_LIST_COMPONENT_USERNAME }}</div>
		<div class="col no-link w-100">{{ labels.DEMO_USER_LIST_COMPONENT_EVENTNAME }}</div>

	</div>
</div>

<ng-template #loadedList>
	<div style="max-height: 39rem; overflow-x: auto;">
		<div class="panel panel-tr" *ngFor="let usr of userList; let i = index">
			<div class="row no-gutters">

				<div class="col">
					<div class="form-group p-0 m-0" *ngIf="!usr?.eventName">
						<div class="custom-control custom-checkbox" style="padding-top: .3rem; margin-left: 1.5rem;">
							<input type="checkbox" class="custom-control-input" id="user-{{i}}" name="user"
								(change)="updateSelected(usr?.userGuid)" />
							<label class="custom-control-label" for="user-{{i}}"></label>
						</div>
					</div>
				</div>
				<div class="col">
					{{ usr?.userName }}
				</div>
				<div class="col w-100">
					{{ usr?.eventName }}
				</div>

			</div>
		</div>
	</div>
</ng-template>


<div *ngIf="isLoading; else loadedList" style="max-height: 39rem; overflow-x: auto; opacity: 0.4; line-height: 0;">
	<div class="panel panel-tr" *ngFor="let ghost of ghostList">
		<div class="row no-gutters">

			<div class="col"></div>
			<div class="col">
				<div class="ghost-line"></div>
			</div>
			<div class="col w-100">
				<div class="ghost-line"></div>
			</div>

		</div>
	</div>
</div>