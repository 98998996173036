import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country, FilterOption, LanguageService, compareCountry } from '../../core';
import { DEFAULT_FILTERS, UserSearchService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component with form to search user list.
*/
@Component({
	selector: 'user-search',
	templateUrl: './user-search.component.html',
	styles: []
})
export class UserSearchComponent extends BaseComponent implements OnInit {

	@Input() filter: FilterOption = {};
	@Output() onUpdateFilter = new EventEmitter<FilterOption>();

	roleOptions: string[];
	roleInfoOptions: string[];
	countryOptions: Country[];
	allStatus: boolean;

	compareCountry = compareCountry;

	constructor(
		private langSrv: LanguageService,
		private usrSearchSrv: UserSearchService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.usrSearchSrv.getDictionaries().subscribe(res => {
			this.countryOptions = this.usrSearchSrv.getCountries();
			this.roleInfoOptions = this.usrSearchSrv.getRoleInfoTypes().map(item => item.value);
			this.roleOptions = this.usrSearchSrv.getRoles().map(item => item.value);
		});
	}

	/**
	* Update user status filters according to all status filter
	*/
	updateAllStatus(): void {
		const value = this.allStatus;
		this.filter.approved = value;
		this.filter.denied = value;
		this.filter.active = value;
		this.filter.suspended = value;
		this.filter.unprocessed = value;
	}

	/**
	* Emit updated filter
	*/
	search(): void {
		this.onUpdateFilter.emit(this.filter);
	}

	/**
	* Clear filters
	*/
	clear(): void {
		this.allStatus = false;
		this.filter = Object.assign({}, DEFAULT_FILTERS);
		this.onUpdateFilter.emit(this.filter);
	}

}
